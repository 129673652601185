import {ApiService} from "./ApiService";
import {UserRegistrationData} from "../types/UserRegistrationData";

const baseUrl = `${process.env.BACKEND_HOST}/services/website/registration`

class RegistrationService extends ApiService {
    async registerUser(registrationData: UserRegistrationData): Promise<Response> {
        const reAssignRegistrationData = Object.assign(
            {email: "", username: "", password: "", userDataPolicyAccepted: false}, registrationData);

        return await fetch(baseUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                //"X-Request-Id": "123456",
                //"X-Request-Client-Key": "ARCK_Must_bePresent"
            },
            body: JSON.stringify(reAssignRegistrationData)
        })
    }
}

export default new RegistrationService();