import {OurServicesType} from "../../types/TypeHelper";
import {ApiService} from "../ApiService";

const baseUrl = `${process.env.BACKEND_HOST}/services/website/our-services`

class ServicesWeOffer extends ApiService {
    async getOurServices(): Promise<OurServicesType[]> {
        const response = await fetch(baseUrl)
        const arrOfServicesWeOffer = await this.apiResponseHandler(response);
        return arrOfServicesWeOffer.json()
    }
}

export default new ServicesWeOffer();