import React, {useEffect, useState} from "react";
import "./TextSlider.scss"
import {Button} from "../../../components/button/Button";

export const TextSlider = () => {
    const [activeTextIndex, setActiveTextIndex] = useState<number>(0)
    const [activeText, setActiveText] = useState<string>()

    const textArr = [
        "For all your IT needs, we are here for you.",
        "We improve processes for better RiZult",
        "Our services are backed by competence, experience & professionalism",
        "We partner & collaborate with you",
        "We get it done with best practices to improve performance"
    ]

    useEffect(() => {
        setActiveText(textArr[activeTextIndex]);
        const slideInterval = setInterval(() => {
            setActiveText(textArr[activeTextIndex]);
            (activeTextIndex < 4) ? setActiveTextIndex(activeTextIndex + 1) : setActiveTextIndex(0)
        }, 7000)

        return () => clearInterval(slideInterval);
    }, [activeText, activeTextIndex]);

    return (
        <div>
            <p>
                {activeText}
            </p>
        </div>
    )
}