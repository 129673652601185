import {InputField} from "../../components/inputText/InputField";
import React, {useState} from "react";
import './UserRegister.scss'
import {Button} from "../../components/button/Button";
import {useForm} from "react-hook-form";
import {CheckBox} from "../../components/checkbox/CheckBox";
import Logo from '../../resources/img/Logo.png';
import registrationService from "../../services/RegistrationService";
import {UserRegistrationData} from "../../types/UserRegistrationData";
import {SuccessPage} from "../../pages/success/SuccessPage";
import {ErrorPage} from "../../pages/error/ErrorPage";

type ActivePage = "" | "register" | "success" | "error";

export const UserRegister = () => {
    const {register, trigger, formState: {errors, isValid}} = useForm({mode: 'onBlur'});
    const [isUserDataChecked, setIsUserDataChecked] = useState(false);
    const [userRegisterData, setUserRegisterData] = useState<UserRegistrationData>({} as UserRegistrationData);
    const [activePage, setActivePage] = useState<ActivePage>("register");

    const onSubmit = async () => {
        await trigger();
        console.log(' userRegisterData: ' + JSON.stringify(userRegisterData) + ' sent to backend');
        console.log('af isValid: ' + isValid);
        const response = await registrationService.registerUser(userRegisterData)
        if (!response.ok) {
            setActivePage('error')
            return
        }
        setActivePage('success')
    };

    // Go to home page
    const goBackToHome = () => {
        // the protocol must be included in the url
        window.location.href = window.location.protocol + "//" + window.location.host + '/home';
    }

    // returns true if all required fields are not empty
    const isActive = () => {
        return isUserDataChecked && isValid;
    }

    const handleUserDataCheckBox = (evt: any) => {
        const target = evt.target;
        if (target.id !== 'user-data-policy') {
            return;
        }

        const isChecked = target.checked;
        setIsUserDataChecked(isChecked);

        const userData = {...userRegisterData}
        userData.userDataPolicyAccepted = isChecked;
        setUserRegisterData(userData);
    }

    const handleUserDataForm = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;
        const userData = {...userRegisterData}
        userData[name] = value


        setUserRegisterData(userData);
    }

    return (
        <div className={"user-register-container"}>

            {
                activePage === "register" &&
                <div className={"user-register-container"}>
                        <span className={"sign-wrapper"}><p style={{textAlign: "center"}}>Already have an account?</p>
                            <Button id={"sign-in"} name={"sign-in"} buttonName={"Sign in"}
                                onClick={() => location.href = "/login"} disabled={false}/>
                        </span>
                    <p style={{textAlign: "center"}}>Registration Form</p>

                    <div>
                        <InputField register={register}
                                    defaultValue={userRegisterData.email} id={"email"} name={"email"} type={"email"}
                                    maxLength={50}
                                    onChange={handleUserDataForm}
                                    placeholder={"Enter Email Address"}
                                    label={"Enter Email Address *"}
                                    required={"Email is Required"}
                                    pattern={{
                                        value: /.*/,
                                        message: "Min. 5 characters are require. Also, these [+?/%§$!] symbols are not allowed."
                                    }}
                                    minLength={5}
                                    errors={errors}/>

                        <InputField register={register} id={"username"} name={"username"} type={"username"}
                                    maxLength={50}
                                    onChange={handleUserDataForm}
                                    placeholder={"Enter Username"}
                                    label={"Enter Username *"}
                                    required={"Username is Required"}
                                    defaultValue={userRegisterData.username}
                                    errors={errors}
                                    pattern={{
                                        value: /.*[a-z]{5,}$/,
                                        message: "Min. 5 characters are required. Also, these [+?/%§$] symbols are not allowed."
                                    }}
                                    minLength={5}
                        />

                        <InputField register={register} id={"password"}
                                    name={"password"}
                                    type={"password"}
                                    maxLength={50}
                                    onChange={handleUserDataForm}
                                    placeholder={"Enter Password"}
                                    label={"Enter Password *"}
                                    required={"Password is Required"}
                                    defaultValue={userRegisterData.password} errors={errors}
                                    pattern={{
                                        value: /[a-z]{8,}$/,
                                        message: "Min. 8 characters are required, Also, these [+?/%§$] symbols are not allowed."
                                    }}
                                    minLength={8}
                        />

                        <InputField register={register} id={"confirmPassword"}
                                    name={"confirmPassword"}
                                    type={"password"}
                                    maxLength={50}
                                    onChange={handleUserDataForm}
                                    placeholder={"Confirm Password"}
                                    label={"Confirm Password *"}
                                    required={"ConfirmPassword is Required"}
                                    defaultValue={userRegisterData.confirmPassword}
                                    errors={errors}
                                    pattern={{
                                        value: /.*[a-z]{8,}$/,
                                        message: "Min. 8 characters are required. Also, these [+?/%§$] symbols are not allowed."
                                    }}
                                    minLength={8}
                        />

                        <div>
                            <CheckBox id={"user-data-policy"} name={"user-data-policy"} checked={isUserDataChecked}
                                      onChange={handleUserDataCheckBox}
                                      label={"I have read and agreed to PeeSoft"}
                            />
                        </div>
                        <p><b>* <span className={"atRiZult-purple"}>are required fields.</span></b></p>
                    </div>
                    <div className={"user-register-buttons-box"}>
                        <Button id={"register-button-back"} name={"register-button-back"} onClick={goBackToHome}
                                buttonName={"Back"} disabled={false}/>
                        <Button className={"register-button-submit"} id={"register-button"} name={"register-button"}
                                onClick={onSubmit} buttonName={"Register"} disabled={!isActive()}/>
                    </div>
                </div>
            }

            {
                activePage === "success" &&
                <SuccessPage message={"Thank you for registering with us! \n\n Please check and confirm your email."}/>
            }

            {
                activePage === "error" && <ErrorPage
                    message={"Something went wrong during the registration! \n\n Please try again later or contact us."}/>
            }

        </div>
    )
}