// custom events to hide header and footer
import {OurServicesType} from "../types/TypeHelper";
import {DropdownOptionsType} from "../components/dropdown/Dropdown";
import {Topics} from "../types/Topic";

export const hideHeaderAndFooter = new CustomEvent("displayHeaderAndFooter", {
    detail: {
        name: "hideHeaderAndFooter"
    },
});

export const extractDropdownOptions = (response: OurServicesType[], state: DropdownOptionsType[], setState: Function, isContactForm?: boolean) => {
    if (response && response.length > 0) {
        response.forEach((service) => {
            state.push({value: service.value, label: service.value})
            setState(state)
        });
    }

    if (isContactForm) {
        const updateServicesDropdown: DropdownOptionsType[] = [...state]
        updateServicesDropdown.push({value: Topics.GENERAL_QUESTION, label: "General Question"});
        setState(updateServicesDropdown)
    }
}

export const OurServiceDropdownOptions = (): DropdownOptionsType[] => {
    return [
        {value: Topics.TECHNOLOGY_ADVISORY, label: "TECHNOLOGY_ADVISORY"},
        {value: Topics.APPLICATION_ENGINEERING, label: "APPLICATION_ENGINEERING"},
        {value: Topics.INFRASTRUCTURE_AND_NETWORKING, label: "INFRASTRUCTURE_AND_NETWORKING"},
        {value: Topics.INTERNET_OF_THINGS, label: "INTERNET_OF_THINGS"},
        {value: Topics.CLOUD_COMPUTING, label: "CLOUD_COMPUTING"},
        {value: Topics.CYBERSECURITY_AND_DATA_PRIVACY, label: "CYBERSECURITY_AND_DATA_PRIVACY"},
        {value: Topics.SCRUM_SERVICE_DELIVERY, label: "SCRUM_SERVICE_DELIVERY"},
        {value: Topics.PROJECT_MANAGEMENT, label: "PROJECT_MANAGEMENT"},
        {value: Topics.QUALITY_ASSURANCE, label: "QUALITY_ASSURANCE"},
        {value: Topics.ACADEMY_AND_TRAINING, label: "ACADEMY_AND_TRAINING"},
        {value: Topics.SUPPORT_AND_MAINTENANCE, label: "SUPPORT_AND_MAINTENANCE"},
        {value: Topics.GENERAL_QUESTION, label: "GENERAL_QUESTION"}]
}