import React, {useEffect, useState} from 'react'
import './Footer.scss'
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYahoo} from "react-icons/fa";
import LogoWhite from '../../../resources/img/LogoWhite.png'
import {AppCookie} from "../cookie/AppCookie";
import {FadeIn} from "react-slide-fade-in";
import {CookiesType, CookiesValues} from "../../../types/CookiesType";
import {useCookies} from "react-cookie";
import {FaI} from "react-icons/fa6";

export const Footer = () => {
    const [cookie, setCookie, removeCookie] = useCookies<CookiesType>();
    const [userConsentCookies, setUserConsentCookies] = useState<CookiesValues>(CookiesValues.EMPTY)

    const isUserConsentCookieAlreadyGiven = (): boolean => {
        let userConsentCookieValue: CookiesValues = getCookieTypeFromCookie(CookiesType.USER_CONSENT);
        setUserConsentCookies(userConsentCookieValue);
        return userConsentCookieValue !== CookiesValues.EMPTY;
    }

    useEffect(() => {
        if (isUserConsentCookieAlreadyGiven()) {
            return;
        }

        document.addEventListener('click', userConsentCookieChangeHandler);
        return () => document.removeEventListener('click', userConsentCookieChangeHandler);
    }, []);

    function userConsentCookieChangeHandler() {
        const userConsentCookieValue: CookiesValues = getCookieTypeFromCookie(CookiesType.USER_CONSENT);
        setUserConsentCookies(userConsentCookieValue);
    }

    const getCookieTypeFromCookie = (cookieName: string): CookiesValues => {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const splitCookies = decodedCookie.split(';');
        for(let i = 0; i < splitCookies.length; i++) {
            let indexedCookie = splitCookies[i];
            while (indexedCookie.charAt(0) == ' ') {
                indexedCookie = indexedCookie.substring(1);
            }
            if (indexedCookie.indexOf(name) == 0) {
                return indexedCookie.substring(name.length, indexedCookie.length) as CookiesValues;
            }
        }
        return CookiesValues.EMPTY;
    }

    const setCookiesHandler = (cookieType: CookiesType, cookieValue: CookiesValues) => {
        removeCookie(cookieType,{path:'/'});
        setCookie(cookieType, cookieValue, { path: '/', maxAge: 2147483647})
    }

    const socialMediaHandler = (destination: string) => {
        //var destination = "instagram://user?username={USERNAME}";
        if( navigator.userAgent.match(/Android/i) ) {
            // use Android's redirect
            document.location = destination;
        }
        else {
            // use iOS redirect
            window.location.replace( destination );
        }
    }

    return(
        <div className={"footer-cookie-container"}>
            <div className={"app-cookie-wrapper"}>
                <FadeIn
                    from="left"
                    positionOffset={400}
                    triggerOffset={200}
                    delayInMilliseconds={3}
                >
                    <AppCookie onClickDecline={() => setCookiesHandler(CookiesType.USER_CONSENT, CookiesValues.DENIED)}
                               onClickAccept={() => setCookiesHandler(CookiesType.USER_CONSENT, CookiesValues.GRANTED)}
                               namedCookie={userConsentCookies}
                    />
                </FadeIn>
            </div>
            <div className={"footer-wrapper"}>
                <a href={"/"} className={"footer-logo-wrapper"}>
                    <img style={{width: "100px"}} src={LogoWhite} alt={"AtRiZult"}/>
                </a>

                <div className={"footer-content-wrapper"}>
                    <div>
                        <div>
                            <div className={"footer-content-grid-wrapper"}>
                                <div>
                                    <p>Services</p>
                                    <nav className={"footer-nav-wrapper"}>
                                        <a href={"/our-services/consultation/application-engineering"}>Digital Transformation</a>
                                        <a href={"/our-services/consultation/academy"}>Workshops & trainings</a>
                                        <a href={"/our-services/consultation/team-setup"}>Team setup</a>
                                        <a href={"/partnership"}>Partnership</a>
                                        <a href={"/our-services/consultation/application-engineering"}>Digital automation</a>
                                    </nav>
                                </div>

                                <div>
                                    <p>About us</p>
                                    <nav className={"footer-nav-wrapper"}>
                                        <a href={"/about-us"}>About AtRiZult</a>
                                        <a href={"/careers"}>Careers</a>
                                        <a href={"/our-team"}>Our Team</a>
                                        <a href={"/investor-relation"}>Investor relations</a>
                                    </nav>
                                </div>

                                <div>
                                    <p>Projects</p>
                                    <nav className={"footer-nav-wrapper"}>
                                        <a href={"/projects"}>Mednager</a>
                                        <a href={"/projects"}>Addressy</a>
                                    </nav>
                                </div>

                                <div>
                                    <p>Contact Us</p>
                                    <nav className={"footer-nav-wrapper"}>
                                        <a href={"/contact-us"}>Contact AtRiZult</a>
                                        <a href={"/consultation"}>Request Consultation</a>
                                        <a href={"/contact-us"}>Visit our office
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"social-media-icons-privacy-wrapper"}>
                        <div className={"privacy-impressum-wrapper"}>
                            <p>AtRiZult &copy; {new Date().getFullYear()}</p>
                            <p>Impressum</p>
                            <p onClick={() => location.href = location.href+"user-privacy"}>User Privacy</p>
                        </div>

                        <div className={"footer-social-media"}>
                            <FaTwitter className={"social-media-class"} color={"#2288e1"} size={"1.5em"}
                                       onClick={() => socialMediaHandler("https://twitter.com")} />
                            <FaYahoo className={"social-media-class"} color={"#8d0eb7"} size={"1.5em"}
                                     onClick={() => socialMediaHandler("https://yahoomail.com")} />
                            <FaLinkedin className={"social-media-class"} color={"#2288e1"} size={"1.5em"}
                                        onClick={() => socialMediaHandler("https://www.linkedin.com/in/patrick- chimeudeonwo-57b97052/")} />
                            <FaFacebook className={"social-media-class"} color={"#2288e1"} size={"1.5em"}
                                        onClick={() => socialMediaHandler("https://facebook.com")} />
                            <FaInstagram className={"social-media-class"} color={"#c90a0a"} size={"1.5em"}
                                         onClick={() => socialMediaHandler("https://instagram.com")} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}