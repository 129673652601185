import React from 'react'
import './ErrorPage.scss'
import {useLocation} from "react-router-dom";

interface ErrorPageProps {
    isError: boolean
    message?: string
    errorTitle?: string
}

export const ErrorPage = (prop: ErrorPageProps) => {
    const pathname = useLocation().pathname
    const pageName = pathname && pathname.split('/').pop()
    const message = prop.message || "404 - error page"

    return (
        <div className={"error-page-container"}>
            {
                !prop.isError &&
                <div>
                    <h3 style={{fontSize: "30px", textAlign: "center"}}>Our {pageName} page is coming very soon...</h3>
                </div>}

            {
                prop.isError &&
                <div>
                    <div>
                        <h3>{prop?.errorTitle}</h3>
                    </div>
                    <div>
                        <p>{message}</p>
                    </div>
                </div>
            }
        </div>
    )
}