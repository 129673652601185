import React, {useEffect, useState} from 'react';
import './HomeApp.scss';
import {useTranslation} from "react-i18next";
import {Button} from "../../components/button/Button";
import photoInBubble from "../../resources/img/photoInBubble.jpg"
import applicationEngineering from "../../resources/img/application-engineering.png"
import technologyAdvisory from "../../resources/img/technology-advisory.png"
import scrum from "../../resources/img/scrum.png"
import cloudSolution from "../../resources/img/cloud-solutions-img.jpeg"
import {TopServices} from "../../modules/topServices/TopServices";
import {EmployeeReview} from "../../modules/employeeReview/EmployeeReview";
import arrowDown from "../../resources/img/arrowDown.png"
import {Teaser} from "../../modules/teaser/Teaser";
import {FadeIn} from "react-slide-fade-in";
import {FcManager} from "react-icons/fc";
import {MdBusinessCenter} from "react-icons/md";
import {LiaHandsHelpingSolid} from "react-icons/lia";
import {TextSlider} from "../../modules/sliders/textSlider/TextSlider";

/**This is the website layout application */
function HomeApp() {
    //TODO: add spinner
    const[isPageLoading, setIsPageLoading] = useState<boolean>(false)
    const[aSecondElapsed, setASecondElapsed] = useState<boolean>(false)
    const { t } = useTranslation();

    useEffect(() => {
        setIsPageLoading(true)
    }, [])

    const getInTouchTeaserAppearanceHandler = () => {
        setTimeout(() => {
            setASecondElapsed(true);
        }, 2000);
    }

    isPageLoading && getInTouchTeaserAppearanceHandler();

  return (
    <div className={"content-container"}>
        <div>
            <div className={"work-at-atrizult blink"}>
                <FadeIn from="bottom" positionOffset={400} triggerOffset={200}  delayInMilliseconds={7} >
                    <Teaser href={"/careers"} text={"Want to work at AtRiZult ?"} buttonName={"Apply"}
                            icon={<FcManager className={"atRiZult-blue"} size={"1.5em"} />}
                    />
                </FadeIn>
            </div>
            <div className={"website-content-wrapper"}>
                <div className={"set-background-image"}>
                    <div className={"background-content-box"}>
                        <div>
                            <TextSlider />
                        </div>
                    </div>
                    {
                        isPageLoading && aSecondElapsed && (
                            <div className={"get-in-touch-teaser-wrapper"}>
                                <FadeIn from="bottom" positionOffset={400} triggerOffset={200} delayInMilliseconds={10} >
                                    <Teaser href={"/our-services/consultation/servicePage=homePage"}
                                            text={"Would you want to turn around your business ?"}
                                            buttonName={"Request Consultation"}
                                            icon={<MdBusinessCenter style={{padding: "0 5px"}} className={"atRiZult-blue resize-teaser-icon-mobile"} />}
                                    />
                                </FadeIn>
                            </div>
                        )
                    }
                </div>

                <div className={"our-services-wrapper append-service-btn-on-home-app"}>
                    <Button id={"our-services-btn-text-slider"} name={"our-services-btn-text-slider"} buttonName={"See in ways we can help"}
                            onClick={() => window.location.href="/our-services"} disabled={false} />
                </div>

                <div className={"text-box-wrapper how-to-help"}>
                    <h3>
                        <LiaHandsHelpingSolid className={"atRiZult-blue resize-home-app-icons"} style={{marginRight: "20px"}} />
                        How can we help in providing you with solutions ?
                    </h3>
                </div>

                <div className={"our-top-services-wrapper"}>
                    <div className={"core-services-boxes-wrapper"}>
                        <TopServices imgSrc={technologyAdvisory} href={"/our-services/consultation/technology-advisory"} serviceName={"Technology Advisory"} />
                        <TopServices imgSrc={applicationEngineering} href={"/our-services/consultation/application-engineering"} serviceName={"Application Engineering"} />
                        <TopServices imgSrc={scrum} href={"/our-services/consultation/scrum-service"} serviceName={"Scrum Service Delivery"} />
                        <TopServices imgSrc={cloudSolution} href={"/our-services/consultation/cloud-solutions"} serviceName={"Cloud Solutions"} />
                    </div>
                </div>

                <div className={"our-services-wrapper"}>
                    <Button id={"our-services-btn"} name={"our-services-btn"} buttonName={"See All Our Services"} onClick={() => window.location.href="/our-services"} disabled={false} />
                </div>

                <EmployeeReview />

                <div className={"bottom-border-line"} />

                <div className={"contact-us-wrapper"}>
                    <div className={"contact-us-arrow-down-icon"} onClick={() => window.location.href="/contact-us"}>
                        <img src={arrowDown} alt={"arrow-down-icon"} />
                    </div>
                    <div className={"contact-us-layers-purple"} />
                    <div className={"contact-us-layers-blue"}>
                        <Button className={"home-contact-us-btn"} id={"contact-us-btn"} name={"contact-us-btn"}
                                buttonName={"Contact Us"} onClick={() => window.location.href="/contact-us"}
                                disabled={false} />
                    </div>
                </div>
            </div>

            {!isPageLoading && <div><p>This is spinner</p></div>}
        </div>
    </div>
  );
}

export default HomeApp;
