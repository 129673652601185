import React, {ReactNode, useState} from "react";
import './Teaser.scss'
import {Button} from "../../components/button/Button";
import classNames from "classnames";
import {IoMdClose} from "react-icons/io";

interface TeaserProps {
    href: string,
    text: string
    buttonName: string
    icon?: ReactNode
}

export const Teaser = (props: TeaserProps) =>  {
    const [closeTeaser, setCloseTeaser] = useState<boolean>(false)
    return (
        <div className={classNames("teaser-container", {"close-teaser": closeTeaser})}>
            <div className={"align-close-icon-right"}>
                <span className={"close-teaser-icon-wrapper"} onClick={() => setCloseTeaser(true)}>
                    <IoMdClose className={"atRiZult-blue"} size={"1.5em"} />
                </span>
            </div>

            <div className={"teaser-text-btn-wrapper"}>
                <div className={"teaser-icon-text-wrapper"}>
                    <p>{props.icon}</p>
                    <p>{props.text}</p>
                </div>
            </div>
            <div className={"teaser-text-btn-wrapper"}>
                <Button id={"user-register-btn"} name={"user-register-btn"} buttonName={props.buttonName} onClick={() => window.location.href=props.href} disabled={false} />
            </div>
        </div>
    )
}