
export enum CookiesType {
    EMPTY = "",
    USER_CONSENT = "userConsentCookie",
    USER_DATA_PRIVACY = "userDataPrivacyCookie",
    LANGUAGE = "Language"

}

export enum CookiesValues {
    EMPTY = "",
    GRANTED = "granted",
    DENIED = "denied"
}