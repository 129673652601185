export enum Topics {
    TECHNOLOGY_ADVISORY = "TECHNOLOGY_ADVISORY",
    APPLICATION_ENGINEERING = "APPLICATION_ENGINEERING",
    INFRASTRUCTURE_AND_NETWORKING = "INFRASTRUCTURE_AND_NETWORKING",
    INTERNET_OF_THINGS = "INTERNET_OF_THINGS",
    CLOUD_COMPUTING = "CLOUD_COMPUTING",
    CYBERSECURITY_AND_DATA_PRIVACY = "CYBERSECURITY_AND_DATA_PRIVACY",
    SCRUM_SERVICE_DELIVERY = "SCRUM_SERVICE_DELIVERY",
    PROJECT_MANAGEMENT = "PROJECT_MANAGEMENT",
    QUALITY_ASSURANCE = "QUALITY_ASSURANCE",
    ACADEMY_AND_TRAINING = "ACADEMY_AND_TRAINING",
    SUPPORT_AND_MAINTENANCE = "SUPPORT_AND_MAINTENANCE",
    GENERAL_QUESTION = "GENERAL_QUESTION"
}