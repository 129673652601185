import {ApiService} from "./ApiService";
import {ContactFormData} from "../types/ContactFormData";

const baseUrl = `${process.env.BACKEND_HOST}/services/website/contact-form`

class ContactFormService extends ApiService {
    async sendContactForm(contactFormData: ContactFormData): Promise<Response> {
        return await fetch(baseUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                //"X-Request-Id": "123456",
                //"X-Request-Client-Key": "ARCK_Must_bePresent"
            },
            body: JSON.stringify(contactFormData)
        })
    }
}

export default new ContactFormService();