import React, {useEffect, useState, JSX} from 'react'
import '../../resources/globalStyle/GlobalStyle.scss'
import './AppContainer.scss'
import {Footer} from "./footer/Footer";
import {Header} from "./header/Header";

interface AppContainerProp {
    children: JSX.Element
}

export const AppContainer = (props: AppContainerProp) => {
    const [hideHeaderAndFooter, setHideHeaderAndFooter] = useState<boolean>(false)

    useEffect(() => {
        document.addEventListener("displayHeaderAndFooter", (e: any) => {
            e.detail.name && e.detail.name == "hideHeaderAndFooter" && setHideHeaderAndFooter(true)
        });
        return () => document.removeEventListener("displayHeaderAndFooter", (e: any) => e);
    })

    return(
        <div className={"app-container"}>
            {
                !hideHeaderAndFooter && (
                    <div className={"sticky app-container-header"}>
                        <Header />
                    </div>
                )
            }

            <div className={"app-container-body"}>
                {props.children}
            </div>

            {
                !hideHeaderAndFooter && (
                    <div className={"app-container-footer"}>
                        <Footer />
                    </div>
                )
            }
        </div>
    )
}