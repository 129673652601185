import {ApiService} from "./ApiService";
import {ConsultationAppointment} from "../types/ConsultationAppointment";
const baseUrl = `${process.env.BACKEND_HOST}/services/website/consultation`
import moment from 'moment';

class ConsultationService extends ApiService {
    async bookConsultationAppointment(appointmentDetails: ConsultationAppointment): Promise<boolean> {
        const newObj = Object.assign(appointmentDetails, {...appointmentDetails,
            appointmentDate: moment(appointmentDetails.appointmentDate).format("DD-MM-YYYY")})

        const response = await fetch(baseUrl, {
            headers: {
                "Content-Type": "application/json",
            },
            method: 'POST',
            body: JSON.stringify(newObj)
        })
        const confirmationDetails = await this.apiResponseHandler(response);
        return confirmationDetails.json()
    }
}

export default new ConsultationService();