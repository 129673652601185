import React, {useState} from "react";
import './InputFileType.scss'
import {FieldErrors, FieldValues, Message, UseFormRegister, ValidationRule} from "react-hook-form";
import {FaCheck} from "react-icons/fa";
import {IoMdClose} from "react-icons/io";

interface InputFileTypeProps {
    id: string,
    name: string,
    defaultValue: any,
    autoFocus?: boolean,
    disabled?: boolean,
    onChange: any,
    register?: UseFormRegister<FieldValues>
    required?: Message | ValidationRule<boolean>,
    pattern?: ValidationRule<RegExp>,
    placeholder?: string,
    minLength?: number,
    maxLength?: number,
    min?: ValidationRule<number | string>,
    max?: ValidationRule<number | string>,
    label?: string,
    errors?: FieldErrors,
    inputSuccessIcon?: JSX.Element
    inputErrorIcon?: JSX.Element
    filename: string
}

export const InputFileType = (props: InputFileTypeProps) => {
    const [touched, setTouched] = useState(false);
    const register = props.register as UseFormRegister<FieldValues>;
    const name = props.name
    const error = props.errors && props.errors[props.name as string];

    const markTouched = () => {
        setTouched(true)
    }

    return (
        <div className={"input-file-type-component"}>
            <span className={"input-file-type-icon-wrapper"}>
                    {
                        !(error?.message) && touched && props.filename && (
                            <i className={"input-file-type-success-icon"}>
                                <FaCheck size={"1em"} />
                            </i>
                        )
                    }
                {
                    error?.message &&
                    <i className={"input-file-type-error-icon"}>
                        <IoMdClose size={"1em"} />
                    </i>
                }
                </span>

            <input
                id={props.id}
                className={"file-upload"}
                /*name={props.name}*/
                type="file"
                disabled={props.disabled}
                {...register(props.name, {
                    required: props.required,
                    pattern: props.pattern,
                    min: props.min,
                    max: props.max,
                    onChange: (e: any) => {
                        props.onChange(e);
                    },
                    onBlur: () => {
                        markTouched()
                    }
                })}
                defaultValue={props.defaultValue}
                maxLength={props.maxLength}
                minLength={props.minLength}
                autoFocus={props.autoFocus}
            />

            {
                error?.message && <span style={{lineHeight: 1, display: "flex"}}>
                    <strong className={"input-file-type-error"}>{error.message as unknown as string}</strong>
                </span>
            }
        </div>
    )
}