import React from "react";
import './AppCookie.scss'
import {CookiesValues} from "../../../types/CookiesType";
import {Button} from "../../../components/button/Button";

interface CookiesProps {
    onLoad?: any,
    namedCookie: CookiesValues,
    onClickAccept: any
    onClickDecline: any
}

export const AppCookie = (props: CookiesProps) => {
    return (
        <div>
            {!props.namedCookie &&
                (<div className={"cookie-wrapper"}>
                    <div className={"cookie-wrapper-child"}>
                        <label>
                            We use cookies in this website to give you the best experience on our
                            site and show you relevant ads. To find out more, read our
                            <a href={"/"}> <strong>privacy policy and cookie policy.</strong></a>
                        </label>
                    </div>
                    <div>
                        <div className={"cookie-buttons"}>
                            <Button disabled={false} id={"accept-cookie-btn"} buttonName={"Accept"} name={"accept-cookie-btn"}
                                    onClick={props.onClickAccept} backgroundColor={"green"}/>
                            <Button disabled={false} id={"decline-cookie-btn"} buttonName={"Decline"} name={"decline-cookie-btn"}
                                    onClick={props.onClickDecline}/>
                        </div>
                    </div>
                </div>)
            }
        </div>
    )
}