import {ApiService} from "./ApiService";
import {JobDetails} from "../types/JobDetails";

const baseUrl = `${process.env.BACKEND_HOST}/services/website/careers`

class CareerService extends ApiService {
    async getCareer(jobId: string, category: string): Promise<JobDetails> {
        const response = await fetch(`${baseUrl}?jobId=${jobId}&category=${category}`)
        const arrOfServicesWeOffer = await this.apiResponseHandler(response);
        return arrOfServicesWeOffer.json()
    }
}

export default new CareerService()