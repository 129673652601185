import {ApiService} from "./ApiService";
import {JobApplication} from "../types/JobApplication";

const baseUrl = `${process.env.BACKEND_HOST}/services/website/job-application`

class JobApplicationService extends ApiService {
    async sendJobApplication(jobApplication: JobApplication): Promise<number> {
        const response = await fetch(baseUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                //"X-Request-Id": "123456",
                //"X-Request-Client-Key": "ARCK_Must_bePresent"
            },
            body: JSON.stringify(jobApplication)
        })
        const jobApplicationId = await this.apiResponseHandler(response);
        return await jobApplicationId.json();
    }

    async sendJobApplicationDocuments(files: FormData, jobApplicationId: number): Promise<Response> {
        return await fetch(`${baseUrl}/${jobApplicationId}`, {
            method: "POST",
            body: files
        })
    }
}

export default new JobApplicationService();