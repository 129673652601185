import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import XHR from "i18next-http-backend" // <---- add this

import commonDe from '../public/locales/de/common.json'
import commonEn from '../public/locales/en/common.json'
import commonNg from '../public/locales/ng/common.json'
import commonFr from '../public/locales/fr/common.json'

const resources = {
    de: { common: commonDe },
    en: { common: commonEn },
    ng: { common: commonNg },
    fr: { common: commonFr }
}

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18n
    .use(XHR) // <---- add this
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // lng: 'en' // <--- turn off for detection to work
        detection: options,
        resources,
        ns: ['common'],
        defaultNS: 'common',
        fallbackLng: 'en',
        supportedLngs: ['de', 'en', 'ng', 'fr'],
        interpolation: {
            escapeValue: false,
        },
        debug: false,
    })

export default i18n