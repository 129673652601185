import React, {useState} from "react";
import './HeaderMenu.scss'
import classNames from "classnames";
import {BiChevronUp, BiChevronDown} from "react-icons/bi";

type Option = { key: number, value: any, href: string }

interface HeaderMenuProps {
    id: string
    ourServices: Option[];
    placeholder: string
    showArrow: boolean
    menuHref: string
}

export const HeaderMenu = (props: HeaderMenuProps) => {
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const menuListLength = props.ourServices.length > 5;

    const headerMenuOnClickHandler = () => {
        //setOpenMenu(!openMenu);
        window.location.href = location.origin+props.menuHref
    }

    return (
        <div className={"header-menu-container"} id={props.id}
             onMouseEnter={() => setOpenMenu(true)}
             onMouseLeave={() => setOpenMenu(false)}
        >
            <div className={"menu-and-icon-wrapper"} id={props.id} onClick={() => headerMenuOnClickHandler()}>
                <div className={classNames("selected-menu-and-icon", "header-desktop-menus")}>
                    <div id={props.id} onClick={() => headerMenuOnClickHandler()}>{props.placeholder}</div>
                    {props.showArrow && (openMenu ? <BiChevronUp color={"gray"} size={"1.5em"} /> :
                        <BiChevronDown color={"gray"} size={"1.5em"} />)}
                </div>
            </div>
            {openMenu &&
                <div className={classNames("menu-wrapper", {"longer-menu-divider": menuListLength, 'services-dropdown-custom-min-width': props.id === 'services'})}>
                    {
                        props.ourServices && props.ourServices.map((option) =>
                            <div className="header-menu-item" id={option.key + ""} key={option.key}>
                                <div className={"menu-item-box"} onClick={() => location.href = option.href}>
                                    {option.value}
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    )
}