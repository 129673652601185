import React, {useEffect, useState} from "react";
import './JobApplicationPage.scss'
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {InputField} from "../../../components/inputText/InputField";
import {Address} from "../../../types/Address";
import {Dropdown} from "../../../components/dropdown/Dropdown";
import json from 'country-region-data/data.json';
import {Button} from "../../../components/button/Button";
import Logo from "../../../resources/img/Logo.png";
import {hideHeaderAndFooter} from "../../../helpers/Helpers";
import {InputFileType} from "../../../components/inputFile/InputFileType";
import jobApplicationService from "../../../services/JobApplicationService";
import {UploadedFiles} from "../../../types/UploadedFiles";
import {JobApplication} from "../../../types/JobApplication";
import {SuccessPage} from "../../success/SuccessPage";

interface FileNames {
    cv: string,
    motivationLetter: string,
    certificates: string
}

export const JobApplicationPage = () => {
    const {jobIdQuery} = useParams()
    const queryParams = jobIdQuery?.split("&")
    const jobId = queryParams && queryParams.shift()?.split("=").pop()?.toUpperCase()
    const {register, trigger, setError, formState: {errors, isValid}} = useForm({mode: 'onBlur'});
    const [address, setAddress] = useState<Address>( {} as Address)
    const [applicationDocuments, setApplicationDocuments] = useState<UploadedFiles[]>([]);
    const [jobApplicationFormData, setJobApplicationFormData] = useState<JobApplication>( {} as JobApplication)
    const [countries, setCountries] = useState<{ value: string, label: string }[]>([])
    const [selectedCountry, setSelectedCountry] = useState<string>()
    const [countryRegions, setCountryRegions] = useState<{ value: string, label: string }[]>([])
    const [selectedCountryRegion, setSelectedCountryRegion] = useState<string>()
    const [filename, setFilename] = useState<FileNames>({} as FileNames);
    const [applicationSubmitted, setApplicationSubmitted] = useState<boolean>(false);
    const urlParams = useParams();

    const countryAndStatesMapper = () => {
        json.forEach((country) => {
            countries.push({value: country.countryName, label: country.countryName})
        })
        setCountries(countries)

        const filterCountryRegion = json
            .filter((con) => con.countryName === selectedCountry)
            .map((states) => states.regions)[0];

        filterCountryRegion && filterCountryRegion.forEach(state => {
            state && countryRegions.push({value: state.name, label: state.name})
        })
        setCountryRegions(countryRegions)
    }

    const jobPositionHandler = () => {
        const jobAppliedFor = urlParams && urlParams.jobIdQuery?.split('&').shift()?.split('=').pop();

        const applicationData = {...jobApplicationFormData}
        jobAppliedFor ? applicationData.positionApplied = jobAppliedFor : null
        setJobApplicationFormData(applicationData)
    }

    useEffect(() => {
        countryAndStatesMapper();

        document.dispatchEvent(hideHeaderAndFooter);
    })

    // on selectedCountry change, reset the selected state
    //TODO: reset state when country is changed
    useEffect(() => {
        setCountryRegions([])
    }, [selectedCountry])

    const jobApplicationDataHandler = (evt: any) => {
        const target = evt.target
        const name = target.name
        const value = target.value

        const jobAppDetails = {...jobApplicationFormData}
        jobAppDetails[name as keyof JobApplication] = value
        setJobApplicationFormData(jobAppDetails)
    }

    const addressHandler = (evt: any) => {
        const target = evt.target
        const id = target.id;
        const value = target.value;

        const applicantAddress = {...address}
        applicantAddress[id as keyof Address] = value
        setAddress(applicantAddress)
    }

    const getSelectedCountryValue = (getSelectedCountry: string) => {
        setSelectedCountry(getSelectedCountry)
        setError("country", {})
    }

    const getSelectedStateValue = (getSelectedState: string) => {
        setSelectedCountryRegion(getSelectedState)
        setError("state", {})
        jobPositionHandler()
    }

    const handleFileChange = (e: any) => {
        const target = e.target;
        const name = target.name;
        const targetFile = target.files;
        const targetFileValue = targetFile[0]
        const targetFileName = targetFileValue.name.toLowerCase()

        const targetFileExt = targetFileName && targetFileName.match(/\.([^\.]+)$/)[1];
        switch (targetFileExt) {
            case 'jpg':
            case 'docx':
            case 'pdf':
            case 'png':
                break;
            default:
                setError(name, {message: "this file type is not allowed"})
                return;
        }

        const spreadFileName = {...filename}
        spreadFileName[name as keyof FileNames] = targetFileName.toLowerCase()
        setFilename(spreadFileName)

        if (applicationDocuments && applicationDocuments.length > 0 && applicationDocuments.filter((item) => item.name === targetFileValue.name).length > 0) {
            setError(name, {type: "file-upload-error", message: "File already exist"})
            return
        }

        const newFileItem = {} as UploadedFiles
        newFileItem.name = name;
        newFileItem.file = targetFileValue

        applicationDocuments.push(newFileItem)
        setApplicationDocuments(applicationDocuments);

        const isDuplicate = applicationDocuments && applicationDocuments.length > 0 && applicationDocuments
            .filter((file) => file.name == name).length > 1;

        if (isDuplicate) {
            //remove the older file
            const newFilter = applicationDocuments
                .filter((file) => file.name == name).shift()

            const index = newFilter && applicationDocuments.indexOf(newFilter, 0);
            if (index && index > -1) {
                applicationDocuments.splice(index, 1);
            }
        }
    };

    const handleUpload = async () => {
        if (applicationDocuments.length < 1) {
            alert('Please select files first'); //TODO: setError
            return;
        }

        await trigger()

        if(!address.country) {
            setError("country", {message: "Please select country of residence", type: "dropdown"})
        }

        if(!address.state) {
            setError("state", {message: "Please select state of residence", type: "dropdown"})
            return
        }

        jobApplicationFormData.address = address
        setJobApplicationFormData(jobApplicationFormData)

        const jobApplicationId: number | void = await jobApplicationService.sendJobApplication(jobApplicationFormData)
            .catch(() => {
                setError("request-error", {message: "Something went wrong while submitting your application, please try again later!"})
            })

        if(!jobApplicationId || jobApplicationId < 1) {
            setError("request-error", {message: "Something went wrong while submitting your application, please try again later!"});
            return
        }

        // add uploaded files
        const formData = new FormData();
        applicationDocuments.forEach((file) => {
            formData.append("files", file.file);
        });

        jobApplicationService.sendJobApplicationDocuments(formData, jobApplicationId)
            .then((response) => {
                if (!response.ok) {
                    return
                }
                setApplicationSubmitted(true);
            })
            .catch(() => {
                setError("request-error", {message: "Something went wrong while submitting your application documents, please try again later!"})
            })
    }

    return (
        <div className={"job-application-component"}>
            <div className={"header-global-wrapper"}>
                <a href={"/"}>
                    <div>
                        <img src={Logo} alt={"AtRiZult"}/>
                    </div>
                    <i className={"hide-text-for-mobile"} style={{fontSize: "smaller"}}>Practical
                        consulting.</i>
                </a>
            </div>
            {
                !applicationSubmitted && (
                    <div>
                        <div className={"job-application-headline"}>
                            <p>Thank you for your interest in working with us!</p>
                            <p><strong>Application Form for <span
                                style={{color: "#4198E8"}}>{jobId && jobId.split('=').pop()?.toUpperCase()}</span> position.</strong>
                            </p>

                        </div>
                        <div className={"job-application-inputs-margin"}>
                            <InputField id={"firstname"} name={"firstname"} register={register}
                                        defaultValue={jobApplicationFormData.firstname}
                                        onChange={jobApplicationDataHandler} placeholder={"Enter Firstname"}
                                        label={"Enter Firstname *"}
                                        required={"Only alphabets are allowed"}
                                        errors={errors}
                                        pattern={{
                                            value: /(^([a-zA-Z]{3,})$)/,
                                            message: "Only alphabets are allowed"
                                        }}
                                        min={9}
                                        minLength={9}
                            />

                            <InputField id={"lastname"} name={"lastname"} register={register}
                                        defaultValue={jobApplicationFormData.lastname}
                                        onChange={jobApplicationDataHandler} placeholder={"Enter Lastname"}
                                        label={"Enter Lastname *"}
                                        required={"Only alphabets are allowed"}
                                        errors={errors}
                                        pattern={{
                                            value: /(^([a-zA-Z]{3,})$)/,
                                            message: "Only alphabets are allowed"
                                        }}
                                        min={9}
                                        minLength={9}
                            />

                            <InputField id={"email"} name={"email"} register={register}
                                        defaultValue={jobApplicationFormData.email}
                                        onChange={jobApplicationDataHandler}
                                        placeholder={"Enter Email"}
                                        label={"Enter Email *"}
                                        required={"A valid email address is required e.g. (yourEmail@gmail.com, email@yahoo.com)"}
                                        errors={errors}
                                        pattern={{
                                            value: /(^["a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~]+[.a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~"]+[@]+[.a-zA-Z0-9_-]+[.]+[a-zA-Z]{2,})/,
                                            message: "A valid email address is required e.g. (yourEmail@gmail.com, email@yahoo.com)"
                                        }}
                            />
                            <Dropdown getSelectedOptionValue={getSelectedCountryValue}
                                      id={"country"}
                                      name={"country"}
                                      errors={errors}
                                      onChange={addressHandler}
                                      options={countries}
                                      placeholder={"Select Country of Residence"}
                            />
                            <Dropdown getSelectedOptionValue={getSelectedStateValue}
                                      id={"state"}
                                      name={"state"}
                                      errors={errors}
                                      onChange={addressHandler}
                                      options={countryRegions}
                                      placeholder={"Select State of Residence"}
                            />
                            <InputField id={"streetNameHouseNumber"} name={"streetNameHouseNumber"} register={register}
                                        defaultValue={address.streetNameHouseNumber}
                                        onChange={addressHandler} placeholder={"Enter Street Name and House Nr."}
                                        label={"Enter Street Name and House Nr. *"}
                                        required={"Only valid street are allowed"}
                                        errors={errors}
                                        pattern={{
                                            value: /^[#.a-zA-Zßöäü\s,-]+[0-9]+$/,
                                            message: "Only valid street are allowed"
                                        }}
                                        min={3}
                                        minLength={9}
                            />
                            <InputField id={"postcode"} name={"postcode"} register={register}
                                        defaultValue={address.postcode}
                                        onChange={addressHandler} placeholder={"Enter Postcode"}
                                        label={"Enter Postcode *"}
                                        required={"Only valid postcodes are allowed"}
                                        errors={errors}
                                        pattern={{
                                            value: /(([0-9])$)/,
                                            message: "Only valid postcodes are allowed"
                                        }}
                                        min={2}
                                        minLength={10}
                            />
                        </div>

                        <div className={"job-application-file-upload-wrapper"}>
                            <p>Please upload your application Documents</p>

                            <div className={"file-select-upload-wrapper"}>
                                <div>
                                    <div className={"upload-label-span-filename-wrapper"}>
                                        <label htmlFor="cv">{"Upload CV"}</label>
                                        {filename.cv && <span className={"align-file-input-filename-icon"}>{filename.cv}</span>}
                                    </div>

                                    <InputFileType name={"cv"} id={"cv"}
                                                   register={register}
                                                   defaultValue={applicationDocuments}
                                                   onChange={handleFileChange}
                                                   required={"Choose your CV file"}
                                                   filename={filename.cv}
                                    />
                                </div>

                                <div>
                                    <div className={"upload-label-span-filename-wrapper"}>
                                        <label htmlFor="motivationLetter">{"Upload Motivation Letter"}</label>
                                        {filename.motivationLetter && <span className={"align-file-input-filename-icon"}>
                            {filename.motivationLetter}
                        </span>}
                                    </div>

                                    <InputFileType id={"motivationLetter"} name={"motivationLetter"}
                                                   register={register}
                                                   defaultValue={applicationDocuments}
                                                   onChange={handleFileChange}
                                                   required={"Motivation Letter"}
                                                   filename={filename.motivationLetter}
                                    />
                                </div>

                                <div>
                                    <div className={"upload-label-span-filename-wrapper"}>
                                        <label htmlFor="certificates">{"Upload certificates (all in a PDF)"}</label>
                                        {filename.certificates && <span className={"align-file-input-filename-icon"}>{filename.certificates}</span>}
                                    </div>

                                    <InputFileType name={"certificates"} id={"certificates"}
                                                   register={register}
                                                   defaultValue={applicationDocuments}
                                                   onChange={handleFileChange}
                                                   required={"Certificates file"}
                                                   filename={filename.certificates}
                                    />
                                </div>

                                <div className={"job-application-submit-btn-wrapper"}>
                                    <Button id={"job-app-upload-file-btn"} name={"job-app-upload-file-btn"} buttonName={"Submit Application"}
                                            onClick={handleUpload} disabled={false}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                applicationSubmitted && <SuccessPage message={"Thank you for applying to work with us!<br><br>We have received your application" +
                    "<br><br> and will get in touch as fast as possible.<br><br>Regards,<br>AtRiZult Recruiting Team"} />
            }
        </div>
    )
}