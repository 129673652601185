import React, {useEffect, useState} from "react";
import  './JobAd.scss'
import Icon from "./benefit-icon.png"
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import {faLocationPin, faBriefcase} from "@fortawesome/free-solid-svg-icons";
import {FaBriefcase, FaCheck} from "react-icons/fa";
import {ImLocation2} from "react-icons/im";
import classNames from "classnames";
import {JobDetails} from "../../types/JobDetails";
import careerService from "../../services/CareerService"

type Category = "" | "PART-TIME" | "FULL-TIME" | "TEMPORARILY" | "CONTRACT"
type Position = "" | "INTERN" | "TRAINEE" | "WORKING-STUDENT" | "MARKETER" | "IT-CONSULTANT" | "CONSULTANT" | "PRODUCT-OWNER"
                | "MARKETING-MANAGER" | "UI-UX-DESIGNER" | "BUSINESS-DEVELOPMENT-MANAGER";
type JobId = "" | "DevOps" | "Business_Development_Manager" | "System_Administrator" | "Product_Owner" | "Software_Engineer" | "Tech_lead"
            | "Software_Architect" | "Quality_Engineer" | "Performance_Engineer" | "Marketing_Manager" | "UiUx_Designer" | "Trainee" | "Working_Student" | "Intern"

interface JobAdProps {
    jobId: JobId
    enabled: boolean
    name?: string
    position?: Position
    requiredExperience?: string
    category: Category
}

export const JobAd = (props: JobAdProps) => {
    const [jobDetails, setJobDetails] = useState<JobDetails>({} as JobDetails)
    const[errors, setErrors] = useState<string>()
    const jobId = props.jobId.toUpperCase();
    const category = props.category.toUpperCase();

    const jobAdsHandler = (jobId: string, category: string) => {
        careerService.getCareer(jobId, category).then((response) => {
            setJobDetails(response);
        }).catch(() => {
            setErrors("Something went wrong please try again later!")
        })
    }

    useEffect(() => {
        jobAdsHandler(jobId, category);
    })

    return (
        <div>
            {
                props.enabled && (
                    <div id={props.jobId.toLowerCase()} className={"job-ad-component-container"}
                         onClick={() => location.href=`/careers/careers-details-page/jobIdQuery=${props.jobId.toLowerCase()}&category=${props.category.toLowerCase()}&title=${props.position}`}
                    >
                        <div className={"job-ad-component-content-wrapper job-portfolio-icon-wrapper"}>
                            {/*<FontAwesomeIcon size={"2xl"} color={"#262200"} icon={faBriefcase} />*/}
                            <FaBriefcase color={"#262200"} size={"1.5em"} />
                        </div>
                        <div className={"job-ad-component-content-wrapper"}>
                            <div className={"job-meta-header"}>
                                <h2 className={classNames({"part-time": props.category === "PART-TIME", "full-time": props.category === "FULL-TIME"})}>
                                    {props.position} - {props.requiredExperience}
                                    <span className={"span-category"}>
                            {props.category}
                        </span>
                                </h2>
                                <div className={"job-location-benefits-wrapper"}>
                                    <span>
                                        {/*<FontAwesomeIcon color={"#4198E8"} icon={faLocationPin} />*/}
                                        <ImLocation2 color={"#4198E8"} size={"1.5em"} />
                                        Enugu, Nigeria
                                    </span>
                                    <span className={"job-benefit-icon"}>
                            <img src={Icon} alt={"Benefits"}/> Work life balance, competitive salary, employee development</span>
                                </div>
                            </div>
                            <div>
                                <p>{jobDetails.description}</p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}