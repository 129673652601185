import React from "react";
import './ErrorNotification.scss'
import {FieldErrors} from "react-hook-form";

interface ErrorNotificationProps {
    name: string
    fieldErrors?: FieldErrors
    stringErrors?: string
}

export const ErrorNotification = (props: ErrorNotificationProps) => {
    const fieldErrors = props.fieldErrors && props.fieldErrors[props.name as string];
    const stringError = props.stringErrors
    let error: any

    if(fieldErrors && !stringError) {
        error = fieldErrors.message
    }

    if(!fieldErrors && stringError) {
        error = stringError
    }

    return (
        <div className={"error-notification"}>
            {
                error && <label>{error}</label>
            }
        </div>
    )
}