import React, {useEffect, useState} from "react";
import './Consultation.scss'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {InputField} from "../../components/inputText/InputField";
import {useForm} from "react-hook-form";
import {SuccessPage} from "../../pages/success/SuccessPage";
import {Button} from "../../components/button/Button";
import {Teaser} from "../teaser/Teaser";
import HowConsultationWorksArrow from '../../resources/img/consultation-how-it-works-arrow.png'
import HowConsultationWorks from '../../resources/img/consultation-booking-description.png'
import {Dropdown, DropdownOptionsType} from "../../components/dropdown/Dropdown";
import {OurServiceDropdownOptions} from "../../helpers/Helpers";
import {ConsultationAppointment} from "../../types/ConsultationAppointment";
import consultationService from "../../services/ConsultationService";

type ActivePage = "ConsultationPage" | "SuccessPage"
type CurrentActionPage = "CalenderPage" | "ContactPage"

interface ConsultationProps {
    serviceDescription?: string
    serviceMetaData?: string
}

export const Consultation = (prop: ConsultationProps) => {
    const todaySDate = new Date();
    const sixMonthsFromToday = new Date(todaySDate.setMonth(todaySDate.getMonth() + 6));
    const [calenderValue, setCalenderValue] = useState<Date>();
    const [currentActionPage, setCurrentActionPage] = useState<CurrentActionPage>("CalenderPage");
    const [activePage, setActivePage] = useState<ActivePage>("ConsultationPage");
    const [consultation, setAppointmentDetails] = useState<ConsultationAppointment>({} as ConsultationAppointment)
    const {register, trigger, setError, formState: {errors, isValid}} = useForm({mode: 'onBlur'});
    const [fromServicePage, setFromServicePage] = useState<boolean>(prop?.serviceMetaData !== undefined)
    const [servicesDropdown, setServicesDropdown] = useState<DropdownOptionsType[]>([])
    const [requestError, setRequestError] = useState<string>()
    const serviceTitle: string = decodeURIComponent(window.location.href.split('/').pop() || "")
    const serviceDescription = localStorage.getItem(serviceTitle)

    useEffect(() => {
        /*servicesWeOffer.getOurServices()
            .then((response) => {
                extractDropdownOptions(response, servicesDropdown, setServicesDropdown)
            })
            .catch(() => {
                setRequestError("Something went wrong please try again later!")
            });*/
        const servicesDropdownNew = OurServiceDropdownOptions();
        setServicesDropdown(servicesDropdownNew)
    }, [])

    const calenderChangeHandler = (selectedDate: Date) => {
        setCalenderValue(selectedDate)
        setCurrentActionPage("ContactPage")
    }

    const tileDisabled = ({activeStartDate, date, view}) => {
        return date < new Date() || [0, 6].includes(date.getDay())
    }

    const contactDetailsHandler = (evt: any) => {
        const target = evt.target;
        const name = target.name;
        const value = target.value;

        const appointmentInfoDetails = {...consultation}
        appointmentInfoDetails.appointmentDate = calenderValue!
        appointmentInfoDetails[name as keyof ConsultationAppointment] = value
        setAppointmentDetails(appointmentInfoDetails)
    }

    const getSelectedOptionValue = (selectedOption: string) => {
        const updateAppointmentInfo = {...consultation}
        updateAppointmentInfo.concern = selectedOption
        setAppointmentDetails(updateAppointmentInfo)
        setError("dropdown", {})
    }

    const scheduleAppointmentBtnHandler = () => {
        consultationService.bookConsultationAppointment(consultation)
            .then(() => {
                // TODO: display meeting info
                setActivePage("SuccessPage")
            })
            .catch((err) => {
                setRequestError("Something went wrong while booking your appointment, please try again later!")
            })
        setActivePage("SuccessPage")
    }

    return (
        <div>
            {activePage === "ConsultationPage" && (
                <div className={"consultation-component"}>
                    <div className={"selected-service-description"}>
                        {
                            serviceDescription && <h1>{serviceTitle}</h1>
                        }
                        <p>{serviceDescription}</p>
                    </div>

                    <div className={"consultation-component-text-box-wrapper"}>
                        <h1>Book Free Consultation Appointment</h1>

                        {/* TODO: This should have a conditional render of selected service metadata if navigated from our services page */}
                        {
                            fromServicePage && (
                                <div>
                                    <p>{prop?.serviceMetaData + " Meta data message of the selected service"}</p>
                                </div>
                            )
                        }

                        <div className={"how-consultation-works"}>
                           <div>
                               <h2 className={"text-box-wrapper"}>How Does it Work ?</h2>
                           </div>
                            <div className={"consultation-arrow-img-resize"}>
                                <img src={HowConsultationWorksArrow} alt={"consultation-how-it-works-arrow"} />
                            </div>
                            <div className={"consultation-how-it-works-img-resize"}>
                                <img src={HowConsultationWorks} alt={"HowConsultationWorks"}/>
                            </div>
                        </div>
                    </div>

                    <div className={"consultation-component-main"}>
                        <div className={"hero-img-and-calender-wrapper"}>
                            <div className="consultation-component-hero">
                                <strong><p className={"consultation-hero-text"}>Let's collaborate</p></strong>
                            </div>
                            <div className="consultation-component-calendar">
                                <h2>Schedule Consultation</h2>
                                {currentActionPage === "CalenderPage" &&
                                    <div>
                                        <Calendar onClickDay={(day) => setCalenderValue(day)}
                                                  onClickMonth={(month) => setCalenderValue(month)}
                                                  onClickYear={(year) => setCalenderValue(year)}
                                                  activeStartDate={calenderValue}
                                                  onChange={(e: any) => calenderChangeHandler(e)}
                                                  value={calenderValue}
                                                  maxDate={sixMonthsFromToday}
                                                  view={"month"}
                                                  calendarType="iso8601"
                                                  defaultView="month"
                                                  maxDetail="month"
                                                  tileDisabled={tileDisabled}
                                        />
                                    </div>
                                }
                                {
                                    currentActionPage === "ContactPage" && (
                                        <div className={"consultation-input-block-wrapper"}>
                                            <div className={"consultation-input-block"}>
                                                <InputField id={"selectedDate"} name={"selectedDate"}
                                                            label={"Appointment Date *"}
                                                            defaultValue={calenderValue?.toDateString() || ""}
                                                            onChange={contactDetailsHandler}
                                                            register={register}
                                                            errors={errors}
                                                            disabled={true}
                                                />
                                                <span className={"change-appointment-date"} onClick={() => setCurrentActionPage("CalenderPage")}>
                                                    Change Date
                                                </span>
                                            </div>

                                            <div className={"consultation-input-block"}>
                                                <InputField id={"appointmentTime"} name={"appointmentTime"}
                                                            label={"Do you have a preferred time?"}
                                                            placeholder={"Time of appointment"}
                                                            defaultValue={consultation.appointmentTime} onChange={contactDetailsHandler}
                                                            register={register}
                                                            errors={errors}
                                                />
                                            </div>

                                           <div className={"consultation-input-block"}>
                                               <InputField id={"phoneNumber"} name={"phoneNumber"}
                                                           label={"Phone Number *"}
                                                           placeholder={"Phone Number"}
                                                           defaultValue={consultation.phoneNumber}
                                                           onChange={contactDetailsHandler}
                                                           register={register}
                                                           errors={errors}
                                               />
                                           </div>

                                            <div className={"consultation-input-block"}>
                                                <InputField id={"email"} name={"email"}
                                                            label={"Email *"}
                                                            placeholder={"Email"}
                                                            defaultValue={consultation.email} onChange={contactDetailsHandler}
                                                            register={register}
                                                            errors={errors}
                                                />
                                            </div>

                                            <div className={"consultation-input-block"}>
                                                <Dropdown getSelectedOptionValue={getSelectedOptionValue}
                                                          name={"consultation-dropdown"}
                                                          errors={errors}
                                                          onChange={contactDetailsHandler}
                                                          options={servicesDropdown}
                                                          placeholder={"Please select your concern"}
                                                />
                                            </div>

                                            <div className={"consultation-input-block"}>
                                                <Button id={"consultation-appt-btn"} name={"consultation-appt-btn"}
                                                        buttonName={"Schedule Consultation"}
                                                        onClick={scheduleAppointmentBtnHandler}
                                                        disabled={false} />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className={"consultation-teaser-wrapper"}>
                            <Teaser href={"/contact-us"} text={"Have any other questions ?"} buttonName={"Get in Touch With us"} />
                        </div>
                    </div>
                </div>)
            }
            {
                activePage === "SuccessPage" && (
                    <div>
                        <SuccessPage message={"Thank you for scheduling consultation!<br><br>We have emailed you the" +
                            "<br><br>details of the consultation.<br><br>Regards,<br>AtRiZult Sales"} />
                    </div>
                )
            }
        </div>
    )
}