import React from "react";
import './SuccessPage.scss'
import * as DOMPurify from 'dompurify';
import {Button} from "../../components/button/Button";

interface SuccessPageProps {
    message: string
}

export const SuccessPage = (prop: SuccessPageProps) => {
    return (
        <div className={"success-page-component"}>
            <div>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prop.message) }}/>

                <Button id={"success-page-home-btn"} name={"success-page-home-btn"}
                        buttonName={"Back to home"} onClick={() => location.href="/"} disabled={false}
                />
            </div>
        </div>
    )
}