import React, {useState} from "react";
import './InputField.scss'
import {FieldValues, UseFormRegister, FieldErrors, Message, ValidationRule} from "react-hook-form";
import {FaCheck} from "react-icons/fa";
import {IoMdClose} from "react-icons/io";
import classNames from "classnames";

export interface InputProps {
    id: string,
    name: string,
    defaultValue: string,
    autoFocus?: boolean,
    disabled?: boolean,
    onChange: any,
    register?: UseFormRegister<FieldValues>
    required?: Message | ValidationRule<boolean>,
    pattern?: ValidationRule<RegExp>,
    placeholder?: string,
    minLength? : number,
    maxLength? : number,
    min?: ValidationRule<number | string>,
    max?: ValidationRule<number | string>,
    label?: string,
    errors?: FieldErrors,
    inputSuccessIcon?:  JSX.Element
    inputErrorIcon?:  JSX.Element
}

export const InputField = (props: InputProps) => {
    const [touched, setTouched] = useState(false);
    // const {register} = useForm();
    const register = props.register as UseFormRegister<FieldValues>;
    const error = props.errors && props.errors[props.name as string];

    //TODO: add success and error icons, parameterized input icons (e.g. email-icon, phone-icon, etc.)

    const markTouched = () => {
        setTouched(true)
    }

    return (
        <div className={"input-field-container"}>
            <label>{props.label}</label>
            <div className={"input-field-wrapper"}>
                <span className={"input-success-icon-wrapper"}>
                    {
                        !(error?.message) && touched && (
                            <i className={"input-success-icon"}>
                                <FaCheck size={"1em"} />
                            </i>
                        )
                    }
                    {
                        error?.message &&
                        <i className={"input-error-icon"}>
                            <IoMdClose size={"1em"} />
                        </i>
                    }
                </span>

                <input className={classNames("format-input", {"input-success": !error && touched, "input-error": error && error.message})}
                       id={props.id}
                       placeholder={props.placeholder}
                       type={"text"}
                       disabled={props.disabled}
                       {...register(props.name, {
                           required: props.required,
                           pattern: props.pattern,
                           min: props.min,
                           max: props.max,
                           onChange: (e: any) => {
                               props.onChange(e);
                           },
                           onBlur: () => {
                               markTouched()
                           }
                       })}
                       defaultValue={props.defaultValue}
                       maxLength={props.maxLength}
                       minLength={props.minLength}
                       autoFocus={props.autoFocus}
                />
                {
                    error?.message && <label style={{lineHeight: 1}}>
                        <strong className={"input-error"}>{error.message as unknown as string}</strong>
                    </label>
                }
            </div>
        </div>
    )
}