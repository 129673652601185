import React from "react";
import './Button.scss'

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
    id: string,
    name: string,
    buttonName: string,
    onClick: any,
    disabled: boolean
    buttonNameColor?: string
    backgroundColor?: string
}

//TODO: add spinner on click i.e. when a user clicks, add spinner to indicate sth is happening and maybe disable the btn

export const Button = (props: ButtonProps) => {
    return (
        <div className={"button-component"}>
            <button className={props.className}
                    id={props.id}
                    name={props.name}
                    disabled={props.disabled}
                    onClick={props.onClick}
            >
                <b style={{color: props.buttonNameColor}}>{props.buttonName}</b>
            </button>
        </div>
    )
}