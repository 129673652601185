import React from "react";
import './Career.scss'
import {JobAd} from "../../modules/jobad/JobAd";

export const Career = () => {
    return (
        <div className={"career-component-container"}>
            <div>
                <div className={"career-background-Image"}>
                    <h2>Our team awaits you</h2>
                </div>
            </div>

            <div className={"text-box-wrapper"}>
                <h2>Job Positions</h2>
            </div>

           <div className={"career-job-ad-wrapper"}>
               <JobAd position={"IT-CONSULTANT"}
                      enabled={true}
                      jobId={"Product_Owner"}
                      requiredExperience={"Product Owner Experience"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"IT-CONSULTANT"}
                      enabled={true}
                      jobId={"DevOps"}
                      requiredExperience={"DevOps Experience"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"IT-CONSULTANT"}
                      enabled={false}
                      jobId={"Tech_lead"}
                      requiredExperience={"Tech Lead Experience"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"IT-CONSULTANT"}
                      enabled={true}
                      jobId={"Software_Architect"}
                      requiredExperience={"Software Architect Experience"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"UI-UX-DESIGNER"}
                      enabled={true}
                      jobId={"UiUx_Designer"}
                      requiredExperience={"UI/UX Experience"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"MARKETING-MANAGER"}
                      enabled={true}
                      jobId={"Marketing_Manager"}
                      requiredExperience={"Consulting Experience"}
                    category={"FULL-TIME"}
               />
               <JobAd position={"BUSINESS-DEVELOPMENT-MANAGER"}
                      enabled={false}
                      jobId={"Business_Development_Manager"}
                      requiredExperience={"Business Development"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"IT-CONSULTANT"}
                      enabled={true}
                      jobId={"System_Administrator"}
                      requiredExperience={"System Administration Experience"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"IT-CONSULTANT"}
                      enabled={true}
                      jobId={"Performance_Engineer"}
                      requiredExperience={"Performance Engineering"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"INTERN"}
                      enabled={true}
                      jobId={"Intern"}
                      requiredExperience={"Zeal To Learn"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"MARKETER"}
                      enabled={true}
                      jobId={"Marketing_Manager"}
                      requiredExperience={"Marketing Experience"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"IT-CONSULTANT"}
                      enabled={true}
                      jobId={"Software_Engineer"}
                      requiredExperience={"Software Engineering"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"TRAINEE"}
                      enabled={true}
                      jobId={"Trainee"}
                      category={"FULL-TIME"}
               />
               <JobAd position={"WORKING-STUDENT"}
                      enabled={true}
                      jobId={"Working_Student"}
                      category={"PART-TIME"}
               />
           </div>
        </div>
    )
}