import React from "react";
import './EmployeeReview.scss'
import dinma from "../../resources/img/dinma.png";
import ahmed from "../../resources/img/ahmed.png";
import udochi from "../../resources/img/udochi.png";
import wolfgang from "../../resources/img/wolfgang.png";
import { MdWork } from "react-icons/md";

export const EmployeeReview = () => {
    return (
        <div className={"employee-review-component"}>
            <div className={"text-box-wrapper working-at-atrizult"}>
                <h3>
                    <MdWork className={"atRiZult-blue resize-home-app-icons"} style={{marginRight: "20px"}} />
                    What is it like working at AtRiZult ?
                </h3>
            </div>
            <div className={"employee-review-section"}>
                <div className={"employee-connector-review-wrapper"}>
                    <div className={"employee-image"}>
                        <span>
                            <div className={"employee-image-background-circle"}>
                                <img src={dinma} alt={"employee"}/>
                            </div>
                            <label htmlFor={"employee-firstname"}>{"Dinma"}</label>
                        </span>
                    </div>
                    <div className={"employee-review-connector line"} />
                    <div className={"employee-review-wrapper"}>
                        <p><i>"AtRiZult has a diverse Client set with interesting and high-value projects."</i></p>
                    </div>
                </div>

                <div className={"employee-connector-review-wrapper"}>
                    <div className={"employee-review-wrapper"}>
                        <p><i>"AtRiZult gives me the opportunity to work with best practices and topnotch technologies.</i>"</p>
                    </div>
                    <div className={"employee-review-connector line"}>
                    </div>
                    <div className={"employee-image"}>
                        <span>
                            <div className={"employee-image-background-circle"}>
                                <img src={ahmed} alt={"employee"}/>
                            </div>
                            <label htmlFor={"employee-firstname"}>{"Ahmed"}</label>
                        </span>
                    </div>
                </div>

                <div className={"employee-connector-review-wrapper"}>
                    <div className={"employee-image"}>
                        <span>
                            <div className={"employee-image-background-circle"}>
                                <img src={udochi} alt={"employee"}/>
                            </div>
                            <label htmlFor={"employee-firstname"}>{"Udochi"}</label>
                        </span>
                    </div>
                    <div className={"employee-review-connector line"}>
                    </div>
                    <div className={"employee-review-wrapper"}>
                        <p><i>"Life work balance is very crucial for us at AtRiZult."</i></p>
                    </div>
                </div>

                <div className={"employee-connector-review-wrapper"}>
                    <div className={"employee-review-wrapper"}>
                        <p><i>"Knowledge sharing is at the core of our daily activities, hence, we grow together as a team."</i></p>
                    </div>
                    <div className={"employee-review-connector line"}>
                    </div>
                    <div className={"employee-image"}>
                        <span>
                            <div className={"employee-image-background-circle"}>
                                {/*<img src={employee} alt={"employee"}/>*/}
                                <img src={wolfgang} alt={"employee"}/>
                            </div>
                            <label htmlFor={"employee-firstname"}>{"Tebah"}</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}