import React, {useState} from "react";
import './Language.scss'
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
import {BiChevronUp, BiChevronDown} from "react-icons/bi";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import ReactCountryFlag from "react-country-flag"
import {useCookies } from "react-cookie";
import {CookiesType} from "../../types/CookiesType";

interface LanguageProps {
    options: {key: number, value: string, language: string}[];
    placeholder: string
}

type SelectedLanguage = {countryCode: string, language: string}

export const Language = (props: LanguageProps) => {
    const [currentLanguage, setCurrentLanguage] = useState<SelectedLanguage>({countryCode: "gb", language: props.placeholder})
    const [isListOpen, setIsListOpen] = useState<boolean>(false)
    const [cookie, setCookie, removeCookie] = useCookies<CookiesType>([]);
    const { i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng).then()
        addLanguageToCookie()
    };

    const selectLanguageHandler = (countryCode: string, language: string) => {
        const languageRef = currentLanguage
        currentLanguage.language = language;
        currentLanguage.countryCode = countryCode
        setCurrentLanguage(languageRef);
        setIsListOpen(false)
        changeLanguage(countryCode)
    }

     const onClickHandler = () => {
        setIsListOpen(!isListOpen)
    }

    const addLanguageToCookie = () => {
        removeCookie(CookiesType.LANGUAGE, { path: '/' })
        setCookie(CookiesType.LANGUAGE, currentLanguage, {path: '/'})
    }

    return (
        <div onLoad={() => addLanguageToCookie()}>
            <div className={"language-container"}
                 onMouseEnter={() => setIsListOpen(true)}
                 onMouseLeave={() => setIsListOpen(false)}
            >
                <div className={"selected-language-and-icon-wrapper"} onClick={onClickHandler}>
                    <div className={classNames("selected-language-and-icon", "header-desktop-menus")}
                         defaultValue={"en"}>
                        <ReactCountryFlag countryCode={currentLanguage.countryCode} svg />
                        <label className={"hide-text-for-mobile"}>{currentLanguage.language}</label>
                    </div>
                    <div className={"selected-language-and-icon"}>
                        {/*<FontAwesomeIcon color={"gray"} name="angle-down" size="xs" icon={isListOpen ? faChevronUp : faChevronDown}/>*/}
                        {
                            isListOpen ? <BiChevronUp name="angle-down" color={"gray"} size={"1.5em"} /> : <BiChevronDown name="angle-down" color={"gray"} size={"1.5em"} />
                        }
                    </div>
                </div>
                { isListOpen &&
                    <div className="languages-wrapper">
                        {
                            props.options && props.options.map((option) =>
                                <object key={option.key} onClick={() => selectLanguageHandler(option.value, option.language)}
                                    onChange={() => i18n.changeLanguage(option.value)}>
                                    <div className={"selected-language-and-icon"}>
                                        <ReactCountryFlag countryCode={option.value} svg />
                                        <label>{option.language}</label>
                                    </div>
                                </object>
                            )
                        }
                    </div>
                }
            </div>
        </div>
    )
}