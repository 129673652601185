import React, {useEffect, useRef, useState} from 'react'
import './ContactForm.scss'
import {Button} from "../../components/button/Button";
import {useForm} from "react-hook-form";
import {InputField} from "../../components/inputText/InputField";
import {Dropdown, DropdownOptionsType} from "../../components/dropdown/Dropdown";
import classNames from "classnames";
import {ContactFormData} from "../../types/ContactFormData";
import {useTranslation} from "react-i18next";
import {TextArea} from "../../components/textarea/TextArea";
import contactFormService from '../../services/ContactFormService'
import {SuccessPage} from "../success/SuccessPage";
import {extractDropdownOptions, hideHeaderAndFooter} from "../../helpers/Helpers";
import Logo from "../../resources/img/Logo.png";
import {ErrorNotification} from "../error/notification/ErrorNotification";
import servicesWeOffer from "../../services/ourServices/ServicesWeOffer";

type AlreadyClientType = "" | "Yes" | "No"

export const ContactForm = () => {
    const [userAlreadyAClient, SetUserAlreadyAClient] = useState<AlreadyClientType>("")
    const {register, trigger, setError, formState: {errors, isValid}} = useForm({mode: 'onBlur'});
    const [contactFormData, setContactFormData] = useState<ContactFormData>({} as ContactFormData)
    const [contactSubmitted, setContactSubmitted] = useState(false)
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const [servicesDropdown, setServicesDropdown] = useState<DropdownOptionsType[]>([])

    useEffect(() => {
        servicesWeOffer.getOurServices()
            .then((response) => {
                extractDropdownOptions(response, servicesDropdown, setServicesDropdown, true)
            })
            .catch(() => {
                setError("dropdown", {message: "Something went wrong please try again later!", type: "dropdown"})
            });
    }, [])

    useEffect(() => {
        document.dispatchEvent(hideHeaderAndFooter);
    })

    const contactFormInputHandler = (evt: any) => {
        const target = evt.target
        const name = target.name
        const value = target.value

        const contactDetails = {...contactFormData}
        contactDetails[name] = value

        setContactFormData(contactDetails)
    }

    const textAreaOnBlurHandler = () => {
        if(!contactFormData.message || (contactFormData.message && !contactFormData.message.replace(/(\r\n|\n|\r)/gm, "").match(/^((?![@~+*§^°!$%]).)*$/)) || (contactFormData.message.length < 20)) {
            setError("message", {message: "These symbols [@~+*§^°!$%] are not allowed and minimum of 20 characters.", type: "message"})
            return
        }
        setError("message", {})
    }
    const getSelectedOptionValue = (selectedOption: string) => {
        const updateContactFormDataTopic = {...contactFormData}
        updateContactFormDataTopic.topic = selectedOption
        setContactFormData(updateContactFormDataTopic)
        setError("dropdown", {})
    }

    const submitContactForm = async() => {
        await trigger();

        if(!contactFormData.topic) {
            setError("dropdown", {message: "Please select your concern", type: "dropdown"})
            return
        }

        if(!contactFormData.message) {
            setError("message", {message: "Message must not be empty", type: "message"})
            return
        }
        setError("message", {})
        setError("dropdown", {})

        await contactFormService.sendContactForm(contactFormData)
            .then((response) => {
                if(!response.ok) {
                    setError("sending-contact-form-error", {message: "Something went wrong while sending your contact, please try again later!"})
                    return
                }
                setContactSubmitted(true)
            })
    }

    return (
        <div className={"contact-us-component-container"}>
            <div className={"header-global-wrapper"}>
                <a href={"/"}>
                    <div>
                        <img src={Logo} alt={"AtRiZult"}/>
                    </div>
                    <i className={"hide-text-for-mobile"} style={{fontSize: "smaller"}}>Practical consulting.</i>
                </a>
            </div>

            <div>
                <h2>Contact AtRiZult</h2>
            </div>

            {
                !contactSubmitted &&
                <div className={"contact-form-box"}>
                    <div className={"already-a-client"}>
                        <h3>{t('HOME.ALREADY_CLIENT')}</h3>
                    </div>

                    <div className={"yes-no-wrapper"}>
                        <label
                            className={classNames("label-yes-no-wrapper", {"label-yes-no-wrapper-on-active": userAlreadyAClient === "Yes"})}
                            onClick={() => SetUserAlreadyAClient("Yes")}>Yes</label>
                        <label
                            className={classNames("label-yes-no-wrapper", {"label-yes-no-wrapper-on-active": userAlreadyAClient === "No"})}
                            onClick={() => {
                                SetUserAlreadyAClient("No")
                                const updateData = {...contactFormData}
                                updateData.clientIdOrEmail = null;
                                setContactFormData(updateData)
                            }}>No</label>
                    </div>

                    <div className={classNames({"contact-content-wrapper": userAlreadyAClient})}>
                        {
                            userAlreadyAClient === "Yes" && (
                                <InputField id={"clientIdOrEmail"} name={"clientIdOrEmail"} register={register}
                                            defaultValue={contactFormData.clientIdOrEmail}
                                            onChange={contactFormInputHandler} placeholder={"Enter Client Id or Email"}
                                            label={"Enter Client Id *"}
                                            required={"Only numbers (client Id) or email address is required"}
                                            errors={errors}
                                            pattern={{
                                                value: /(^(["a-zA-Z0-9#!$%&'*+\-\/=?^_`{}|~]+[.a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~"]+[@]+[.a-zA-Z0-9_-]+[.]+[a-zA-Z]{2,})|^([0-9]{9})$)/,
                                                message: "Only numbers (client Id) or email address is required"
                                            }}
                                            min={9}
                                            minLength={9}
                                />
                            )
                        }

                        {
                            userAlreadyAClient === "No" && (
                                <div className={"contact-form-wrapper"}>

                                    <InputField id={"firstname"} name={"firstname"} register={register}
                                                defaultValue={contactFormData.firstname}
                                                onChange={contactFormInputHandler}
                                                placeholder={"Enter Firstname"}
                                                required={"Only alphabets & minimum of 2 character is required"}
                                                label={"Enter Firstname *"}
                                                errors={errors}
                                                pattern={{
                                                    value: /^((?![+#0-9\]\[@!"><$§|)(=`*^%]).)*$/,
                                                    message: "Only alphabets & minimum of 2 character is required"
                                                }}
                                                min={2}
                                                minLength={2}
                                    />
                                    <InputField id={"lastname"} name={"lastname"} register={register}
                                                defaultValue={contactFormData.lastname}
                                                onChange={contactFormInputHandler}
                                                placeholder={"Enter Lastname"}
                                                label={"Enter Lastname *"}
                                                required={"Only alphabets & minimum of 2 character is required"}
                                                errors={errors}
                                                pattern={{
                                                    value: /^((?![+#0-9\]\[@!"><$§|)(=`*^%]).)*$/,
                                                    message: "Only alphabets & minimum of 2 character is required"
                                                }}
                                                min={2}
                                                minLength={2}
                                    />
                                    <InputField id={"email"} name={"email"} register={register}
                                                defaultValue={contactFormData.email}
                                                onChange={contactFormInputHandler}
                                                placeholder={"Enter Email"}
                                                label={"Enter Email *"}
                                                required={"A valid email address is required e.g. (yourEmail@gmail.com, email@yahoo.com)"}
                                                errors={errors}
                                                pattern={{
                                                    value: /(^["a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~]+[.a-zA-Z0-9#!$%&'*+-\/=?^_`{}|~"]+[@]+[.a-zA-Z0-9_-]+[.]+[a-zA-Z]{2,})/,
                                                    message: "A valid email address is required e.g. (yourEmail@gmail.com, email@yahoo.com)"
                                                }}
                                    />
                                    <InputField id={"phoneNumber"} name={"phoneNumber"} register={register}
                                                defaultValue={contactFormData.phoneNumber}
                                                onChange={contactFormInputHandler}
                                                placeholder={"Enter Phone Number"}
                                                label={"Enter Phone Number *"}
                                                required={"Phone number in one of these formats (08012345678 or +2348012345678) is required"}
                                                errors={errors}
                                                pattern={{
                                                    value: /^(^[+][0-9]{6,15}|^[0-9]{6,15})$/,
                                                    message: "Phone number in one of these formats (08012345678 or +2348012345678) is required"
                                                }}
                                                min={6}
                                                minLength={6}
                                    />
                                </div>
                            )
                        }

                        {
                            userAlreadyAClient && (
                                <>
                                    <div>
                                        <div className={"contact-form-items-margin"} ref={dropdownRef}>
                                            <Dropdown getSelectedOptionValue={getSelectedOptionValue}
                                                  name={"dropdown"}
                                                  errors={errors}
                                                  onChange={contactFormInputHandler}
                                                      options={servicesDropdown}
                                                  /*options={[
                                                      {value: "IT Services", label: "IT Services"},
                                                      {value: "Construction", label: "Construction"},
                                                      {value: "Client Support", label: "Client Support"},
                                                      {value: "Update Contact Details", label: "Update Contact Details"},
                                                      {value: "General Question", label: "General Question"}
                                                      ]}*/
                                                  placeholder={"Please select your concern"}
                                            />
                                        </div>

                                        <div className={"contact-form-items-margin"}>
                                            <TextArea
                                                id={"message"}
                                                name={"message"}
                                                placeholder={"Enter your message"}
                                                errors={errors}
                                                value={contactFormData.message}
                                                onChange={contactFormInputHandler}
                                                onBlur={textAreaOnBlurHandler}/>
                                        </div>
                                    </div>

                                    <div className={"contact-us-btn-wrapper"}>
                                        <Button id={"contact-us"} name={"contact-us"} buttonName={"Send your message"}
                                                onClick={submitContactForm} disabled={false /*!isValid*/}/>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    {
                        errors && errors["sending-contact-form-error"] && errors["sending-contact-form-error"].message && (
                            <ErrorNotification name={"sending-contact-form-error"} fieldErrors={errors}  />
                        )
                    }
                </div>
            }
            {
                contactSubmitted && <SuccessPage message={"Thank you for contacting us!<br><br>We have received your message" +
                    "<br><br>and will get in touch as fast as possible.<br><br>Regards,<br>AtRiZult Customer Support"} />
            }
        </div>
    )
}