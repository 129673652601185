import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {AppContainer} from "./modules/appContainer/AppContainer";
import {CookiesProvider} from "react-cookie";
import './i18n';
import {MainApplication} from "./MainApplication";
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <React.Suspense>
            <QueryClientProvider client={queryClient}>
                <AppContainer children={MainApplication()}/>
            </QueryClientProvider>
        </React.Suspense>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
