import React from "react";
import "./TopServices.scss"

export const TopServices = (props: {imgSrc: string, href: string, serviceName: string}) => {
    return (
        <div className={"core-services-boxes"}>
            <a href={props.href}>
                <div className={"circle-box"}>
                    <img style={{width: "194.86px", height: "194.86px", borderRadius: "50%"}} src={props.imgSrc} alt={props.serviceName} />
                </div>
                <div className={"service-name-style"}>
                    <p>{props.serviceName}</p>
                </div>
            </a>
        </div>
    )
}