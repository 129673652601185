import React, {useEffect, useRef, useState} from "react"
import "./Header.scss"
import Logo from "../../../resources/img/Logo.png";
import {Language} from "../../language/Language";
import MenuIcon from "../../../resources/img/MenuIcon.png"
import classNames from "classnames";
import {HeaderMenu} from "../../headerMenu/HeaderMenu";
import {OurServicesType} from "../../../types/TypeHelper";
import servicesWeOffer from "../../../services/ourServices/ServicesWeOffer";
import {AiOutlineUser} from "react-icons/ai";

export const Header = () => {
    const[isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)
    const [serviceWeOffer, setServiceWeOffer] = useState<OurServicesType[]>()
    const[isMobile, setIsMobile] = useState(window.innerWidth <= 960);
    const headerMenuRef = useRef(null)

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 960);
    }

    useEffect(() => {
        servicesWeOffer.getOurServices()
            .then((resp) => {
                resp && resp.length > 0 && setServiceWeOffer(resp)
            })
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    const mobileMenuOutsideClickHandler = (evt: any) => {
        if (isMobileMenuOpen && headerMenuRef && !headerMenuRef.current.contains(evt.target)) {
            setIsMobileMenuOpen(false)
        }
    }

    const disableLanguageLogin = (): boolean => {
        return JSON.stringify(process.env.ENABLE_LANGUAGE_AND_LOGIN) === JSON.stringify("true");
    }

    return (
        <div className={"header-wrapper"} id={"atrizult-header"} onClick={mobileMenuOutsideClickHandler}>
            <header className={"header-container"}>
                <div className={"header-container-children"}>
                    <div className={"menu-icon-wrapper"}>
                        <img id={"hamburger-icon"} className={"atRiZult-black toggle-menu-icon-for-mobile-desktop"} src={MenuIcon} alt={"Hamburger-icon"}
                             onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
                        <div className={"logo-title-wrapper hide-logo-for-mobile-desktop"}>
                            <a href={"/"}>
                                <div>
                                    <img src={Logo} alt={"AtRiZult"}/>
                                </div>
                                <i className={"hide-text-for-mobile"} style={{fontSize: "smaller"}}>Practical
                                    consulting.</i>
                            </a>
                        </div>
                    </div>
                </div>

                <div id={"header-menus-wrapper"}
                     className={classNames("header-menus-wrapper w3-animate-left", {"toggle-mobile-desktop-view": isMobile && isMobileMenuOpen})}
                     onMouseLeave={() => setIsMobileMenuOpen(false)}
                >
                    <div className={"activate-desktop-menu"} id={"activate-desktop-menu"} ref={headerMenuRef}>
                        <div className={"header-menu-child"} id={"header-menu-child"}>
                            <HeaderMenu id={"about-us"} ourServices={[{key: 0, value: "About AtRiZult", href: "/about-us"}, {key: 1, value: "Careers", href: "/careers"},
                                {key: 2, value: "Our team", href: "/our-team"}, {key: 3, value: "Investor relations", href: "/investor-relation"}]}
                                        menuHref={"/about-us"} showArrow={true} placeholder={"About us"}
                            />
                        </div>
                        {
                            serviceWeOffer && (
                                <div className={"header-menu-child"}>
                                    <HeaderMenu id={"services"} ourServices={/*serviceWeOffer*/ []} placeholder={"Services"}
                                                menuHref={"/our-services"} showArrow={false}
                                    />
                                </div>
                            )
                        }

                        <div className={"header-menu-child"}>
                            <HeaderMenu id={"projects"} ourServices={[{key: 0, value: "Addressy", href: "/projects"},
                                {key: 1, value: "Mednager", href: "/projects"}]} showArrow={true} menuHref={"/projects"}
                                        placeholder={"Projects"}
                            />
                        </div>

                        <div className={"header-menu-child"}>
                            <HeaderMenu id={"clients"} ourServices={[]} showArrow={false}  placeholder={"Clients"} menuHref={"/clients"}
                            />
                        </div>

                        <div className={"header-menu-child"}>
                            <HeaderMenu id={"contact-us"} showArrow={true} menuHref={"/contact-us"}
                                ourServices={[{key: 0, value: "Contact AtRiZult", href: "/contact-us"},
                                {key: 1, value: "Request Consultation", href: "/our-services/consultation/servicePage=contactPage", }]}
                                placeholder={"Contact us"}
                            />
                        </div>
                    </div>
                </div>

                <div className={"header-container-children toggle-menu-icon-for-mobile-desktop"}>
                    <div>
                        <a href={"/"}>
                            <div className={"logo-title-wrapper"}>
                                <div>
                                    <img src={Logo as unknown as string} alt={"AtRiZult"}/>
                                </div>
                                <i className={"hide-text-for-mobile"} style={{fontSize: "smaller"}}>Practical consulting.</i>
                            </div>
                        </a>
                    </div>
                </div>

                {/*TODO: disable in prod*/}
                {
                    disableLanguageLogin() &&
                    (<div className={"header-container-children"}>
                        <div className={"language-user-icon-wrapper"}>
                            <div className={"align-language-user-icon"} onClick={() => location.href = "/register"}>
                                <AiOutlineUser className={"atRiZult-black"} size={"1.5em"} />
                            </div>
                            <div className={"align-language-user-icon"}>
                                <Language options={[{key: 0, value: "gb", language: "English"}, {
                                    key: 1,
                                    value: "ng",
                                    language: "Igbo"
                                },
                                    {key: 2, value: "ng", language: "Hausa"}, {key: 3, value: "ng", language: "Yoruba"},
                                    {key: 4, value: "de", language: "German"}, {
                                        key: 5,
                                        value: "fr",
                                        language: "French"
                                    }]}
                                          placeholder={"English"}/>
                            </div>
                        </div>
                    </div>)
                }
            </header>
        </div>
    )
}