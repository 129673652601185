import React, {useEffect, useState} from "react";
import './CareerDetailPage.scss'
import {Button} from "../../../components/button/Button";
import {useParams} from "react-router-dom";
import classNames from "classnames";
import {useQuery} from "react-query";
import {JobDetails} from "../../../types/JobDetails";
import careerService from "../../../services/CareerService";
import {FaBriefcase, FaClock} from "react-icons/fa";
import {ImLocation2} from "react-icons/im";

export const CareerDetailPage = () => {
    const {jobIdQuery} = useParams()
    const queryParams = jobIdQuery?.split("&")
    const jobPosition = queryParams && queryParams.shift()?.split("=").pop()?.toUpperCase()
    const category = jobPosition && queryParams.shift()?.split("=").pop()?.toUpperCase()
    let jobTitle = queryParams && queryParams[0].split("=").pop();
    jobTitle = jobTitle && jobTitle.split('-').pop();

    const [jobDetails, setJobDetails] = useState<JobDetails>({} as JobDetails)
    const [errors, setErrors] = useState<string>()
    const [jobResponsibilities, setJobResponsibilities] = useState<string[]>()
    const [jobRequirements, setJobRequirements] = useState<string[]>()
    const [isButtonAtTheTop, setIsButtonAtTheTop] = useState(false)

    const jobDetailsHandler = async () => {
        careerService.getCareer(jobPosition || "", category || "")
            .then((response) => {
                setJobDetails(response);
                if (response.jobRequirements && response.jobResponsibilities) {
                    setJobResponsibilities(response.jobResponsibilities.split("\n"))
                    setJobRequirements(response.jobRequirements.split("\n"))
                    setErrors(undefined)
                }
            }).catch(() => {
            setErrors("Something went wrong please try again later!")
        })
    }

    const {isLoading, isFetched, refetch, isSuccess, error, isError, data} = useQuery({
        queryKey: ['careers'],
        queryFn: jobDetailsHandler
    })

    useEffect(() => {
        !isFetched && setErrors("Loading ...");
        if (!isSuccess) {
            refetch({
                queryKey: ['careers']
            }).then(r => r)
        }
    }, [])

    // Add event listener to make button appear when scrolling down
    window.addEventListener('scroll', () => {
        if (window.scrollY > 450 && window.scrollY < 1850) {
            setIsButtonAtTheTop(true);
            return
        }
        setIsButtonAtTheTop(false);
    });

    const scrollToTop = isButtonAtTheTop ? 'show-scroll-btn' : ''

    return (
        <div className={"career-detail-page-component"}>
            <div className={"job-description-title-btn-wrapper"}>
                <span>{jobPosition && jobPosition.concat(" position").toUpperCase()} </span>

                <span className={classNames("job-category-wrapper", {
                    "full-time": category === "FULL-TIME",
                    "part-time": category === "PART-TIME"
                })}>
                    <i>{category || "FULL-TIME"}</i>
                </span>
                {!errors && <Button id={"career-apply-btn"} name={"career-apply-btn"} buttonName={"Apply"}
                         onClick={() => location.href = location.href + "/apply"} disabled={false}/>}
            </div>

            {
                !errors && (
                    <div className={"job-description-wrapper"}>
                        <div className={"job-description-wrapper-children"}>
                            <div className={"job-description-margin-divider"}>
                                <h3>Description:</h3>
                                <p>{jobDetails.description}</p>
                            </div>
                            <div className={"job-description-margin-divider"}>
                                <h3>Your responsibilities as a <strong
                                    style={{color: "#09053AFF"}}>{jobPosition} </strong> will include:</h3>
                                <ul>
                                    {
                                        jobResponsibilities && jobResponsibilities.map((responsibilities) => (
                                            responsibilities && responsibilities.length > 1 &&
                                            <li key={Math.floor(Math.random() * (700 - 50) + 50)}>{responsibilities}</li>
                                        ))
                                    }
                                </ul>
                            </div>

                            <div className={"job-description-margin-divider"}>
                                <h3>What we expect you to have:</h3>
                                <ul>
                                    {
                                        jobRequirements && jobRequirements.map((requirements) => (
                                            requirements && requirements.length > 1 &&
                                            <li key={Math.floor(Math.random() * (800 - 90) + 90)}>{requirements}</li>
                                        ))
                                    }
                                </ul>
                            </div>

                            <div className={"job-description-margin-divider job-description-closing-remark"}>
                                <p>{jobDetails.closeRemark}</p>
                            </div>
                        </div>
                        <div className={"job-description-wrapper-children job-overview-wrapper"}>
                            <div className={"job-overview-block"}>
                                <p style={{textAlign: 'center'}}><strong>Opening Overview</strong></p>
                            </div>
                            <div className={"job-overview-block"}>
                                <div className={"job-overview-block-children"}>
                                    <p>
                                        <span style={{marginRight: '5px'}}>
                                            <ImLocation2 color={"#4198E8"} size={"1em"} />
                                        </span>
                                        <strong>Location</strong>
                                    </p>
                                    <p style={{paddingLeft: '20px'}}>Chime Avenue Enugu Nigeria</p>
                                </div>
                                <div className={"job-overview-block-children"}>
                                    <p>
                                        <span style={{marginRight: '5px'}}>
                                            <FaBriefcase color={"#4198E8"} size={"1em"} />
                                        </span>
                                        <strong>Job Title</strong>
                                    </p>
                                    <p style={{paddingLeft: '20px'}}>{jobPosition?.toLowerCase()} {jobTitle?.toLowerCase()}</p>
                                </div>
                                <div className={"job-overview-block-children"}>
                                    <p>
                                        <span style={{marginRight: '5px'}}>
                                            <FaClock color={"#4198E8"} size={"1em"} />
                                        </span>
                                        <strong>Hours</strong>
                                    </p>
                                    <p style={{paddingLeft: '20px'}}>Flexible hours</p>
                                </div>
                            </div>
                            <div className={"job-apply-sticky-btn " + scrollToTop}>
                                <Button id={"career-apply-btn"} name={"career-apply-btn"} buttonName={"Apply"}
                                        onClick={() => location.href = location.href + "/apply"} disabled={false}/>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                errors && <div className={"career-detail-page-content-and-error-wrapper"}><p
                    className={"text-box-wrapper"}>{errors}</p></div>
            }
        </div>
    )
}