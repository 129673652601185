import React from 'react'
import './OurServices.scss'
import {OurServiceAd} from "../../modules/ourservices/OurServiceAd";
import CoreCompetencies from '../../resources/img/core-competencies-img.jpeg'
import {Teaser} from "../../modules/teaser/Teaser";
import { GiDiscussion } from "react-icons/gi";
import { BsDatabaseFillGear, BsFillQuestionCircleFill } from "react-icons/bs";
import {FaNetworkWired} from "react-icons/fa";
import { LuRadioTower } from "react-icons/lu";
import { AiOutlineCloud, AiOutlineSecurityScan } from "react-icons/ai";
import { DiScrum } from "react-icons/di";
import { MdOutlineManageAccounts, MdOutlineHighQuality, MdSupportAgent } from "react-icons/md";
import { SiHtmlacademy } from "react-icons/si";

export const OurServices = () => {
    return (
        <div className={"our-services-component"}>
            <div className={"our-services-component-hero-image"}>
                <div className={"our-services-component-header-text"}>
                    <h2>AtRiZult IT Consulting</h2>
                    <i>Empowering You with Cutting-Edge Technologies</i>
                </div>

                <div className={"explore-our-services"}>
                    <a href={"#our-service-offers"}> Explore Our Services </a>
                </div>
            </div>

            <div id={"our-service-offers"} className={"text-box-wrapper"}>
                <h3>Services we can offer you cuts across below IT services and many more ...</h3>
            </div>

            <div className={"our-services-component-ad-wrapper"}>
                <div className={"align-service-ad-box"}>
                    <div className={"our-service-ad-grid-container"}>
                        <OurServiceAd
                            title={"Technology Advisory"} /*A.K.A IT Strategy and Planning*/
                            metaData={"Making sure that your IT strategy aligns with technology initiatives to thrive your overall " +
                                "business goal, we work with you to better your technology capabilities geared towards performance improvement, efficiency, cost reduction. "
                                }
                            serviceDescription={"Would you want to embark on an IT journey and do not know where to start from or " +
                                "have IT infrastructure that requires an expert attention or want to purchase IT equipment or want to " +
                                "decide for solution to either adopt or discard, no matter what your technology need is, our IT experts will be " +
                                "right beside you to guide your appropriately."}
                            icon={<GiDiscussion className={"single-service-icon"} />}
                        />

                        {/*
                            TODO: add service description which shall be displayed on the consultation top page.
                            in addition to meta data, include other core information, e.g. automation, digitization, etc.
                        */}
                        <OurServiceAd
                            title={"Application Engineering"}
                            metaData={"The technical & business merits of topnotch application cannot be over emphasized. Whatever application needs " +
                                "you may have, we deliver working-, maintainable-, scalable- Web, Mobile, Ops applications accordingly."}
                            /*Digital automation, website and mobile applications, hosting, test automation, etc*/
                            serviceDescription={"Professional website, mobile, desktop application development, portal development, digital automation of business " +
                                "processes, database hosting and management, email hosting and templating, domain hosting, website hosting, plugins delivery are offers we " +
                                "have for you under our application engineering service."}
                            icon={<BsDatabaseFillGear className={"single-service-icon"} />}
                        />

                        <OurServiceAd
                            title={"Infrastructure & Network"}
                            metaData={"Is designing scalable & secure IT infrastructures that support your business operations " +
                                "efficiently among your current challenges, then reach out to us let's get started."}
                            serviceDescription={"Our Infrastructure & Network Services ensures that private individuals or businesses " +
                                "have stable and secure internet connections and computer systems; for example, we can set up and " +
                                "maintain Wi-Fi networks in your homes or offices, ensuring seamless internet access and protecting " +
                                "against cyber threats."}
                            icon={<FaNetworkWired className={"single-service-icon"} />}
                        />

                        <OurServiceAd
                            title={"Internet of Things (IoT)"}
                            metaData={"Nowadays, IoT has revolutionized the way we interact with technology. Our comprehensive IoT experts enable " +
                                "businesses to harness the power of interconnected devices& data to drive innovation & efficiency."}
                            serviceDescription={"In our IoT service, we can help connect, control, and optimize your devices and systems, " +
                                "making your home or business smarter and more efficient through IoT services."}
                            icon={<LuRadioTower className={"single-service-icon"} />}
                        />
                        <OurServiceAd
                            title={"Artificial Intelligence (AI)"}
                            metaData={"Nowadays, IoT has revolutionized the way we interact with technology. Our comprehensive IoT experts enable " +
                                "businesses to harness the power of interconnected devices& data to drive innovation & efficiency."}
                            serviceDescription={"Our Artificial Intelligence (AI) services can help you and/or your businesses by creating " +
                                "smart technology solutions that automate tasks, analyze data, and enhance decision-making processes, making everyday " +
                                "activities and complex business operations more efficient and effective."}
                            icon={<LuRadioTower className={"single-service-icon"} />}
                        />


                        <OurServiceAd
                            title={"Cloud Computing Solutions"}
                            metaData={"Cloud computing solutions enable you to harness the power of the cloud, reduce costs, & increase " +
                                "flexibility. Be it public, private, or hybrid cloud solutions, we ensure smooth implementation & support."}
                            serviceDescription={"The Cloud Computing Solutions Service we provide offers individuals and businesses with secure, convenient, " +
                                "and scalable access to computer resources and software applications over the internet, enabling them to store data, " +
                                "run applications, and streamline their operations without the need for physical hardware or technical expertise."}

                            icon={<AiOutlineCloud className={"single-service-icon"} />}
                        />

                        <OurServiceAd
                            title={"Cybersecurity & Data Privacy"}
                            metaData={"Protecting your business from cyber threats is our top priority. Our IT consultants have extensive experience " +
                                "in developing robust cybersecurity frameworks tailored to your organization's needs."}
                            serviceDescription={"A cybersecurity and data privacy service helps safeguard sensitive information and digital assets for individuals " +
                                "and businesses, such as implementing secure encryption methods to protect online transactions and confidential client data from " +
                                "cyber threats."}
                            icon={<AiOutlineSecurityScan className={"single-service-icon"} />}
                        />

                        <OurServiceAd
                            title={"SCRUM Service Delivery"}
                            metaData={"With our efficient and collaborative approach to project management built upon the principles of " +
                                "transparency, adaptability, and continuous improvement, our teams are empowered to deliver " +
                                "exceptional results to boost your business goals."}
                            serviceDescription={"SCRUM Service Delivery Service helps clients efficiently manage projects by providing structured planning, " +
                                "continuous communication, and adaptive problem-solving, ensuring timely delivery of products or services; for example, " +
                                "assisting a company in developing a software solutions through iterative development cycles, ensuring constant feedback and" +
                                " on-time delivery."}
                            icon={<DiScrum className={"single-service-icon"} />}
                        />

                        <OurServiceAd
                            title={"Project Management"}
                            metaData={"Efficient project management is crucial for successful technology initiatives. " +
                                "Our IT project management services ensure that your projects are executed seamlessly from start to finish."}
                            serviceDescription={"Under the Project Management Services, we help individuals and/or businesses efficiently plan, " +
                                "execute, and complete projects by organizing tasks, allocating resources, and ensuring timely delivery, " +
                                "such as overseeing the construction of a new office building for a corporate client to ensure it stays on " +
                                "schedule and within budget."}
                            icon={<MdOutlineManageAccounts className={"single-service-icon"} />}
                        />

                        <OurServiceAd
                            title={"Quality Assurance"}
                            metaData={"At our core, we prioritize excellence and customer satisfaction. With our meticulous attention to detail " +
                                "and rigorous testing methodologies, we ensure that your products & services meet the highest standards of quality."}
                            serviceDescription={"Our Quality Assurance Service ensures that products or services delivered to you meet high standards, " +
                                "such as providing rigorous testing for your software solutions to ensure they function flawlessly and meet user " +
                                "expectations before they are launched to the public or businesses."}
                            icon={<MdOutlineHighQuality className={"single-service-icon"} />}
                        />

                        <OurServiceAd
                            title={"Academy & Training"}
                            metaData={"We empower individuals & organizations to unlock their full potential through comprehensive training. " +
                                "Whether to upskill your workforce, enhance personal capabilities, or stay ahead of industry trends."}
                            serviceDescription={"The Academy & Training Services provides specialized learning programs, like workshops " +
                                "and courses, to individuals or businesses, helping them enhance skills and knowledge; for instance, teaching employees " +
                                "IT skills to increase output."}
                            icon={<SiHtmlacademy className={"single-service-icon"} />}
                        />

                        <OurServiceAd
                            title={"Support & Maintenance"}
                            metaData={"We understand the importance to keep your systems, applications, & infrastructure running smoothly. " +
                                "We provide reliable and efficient maintenance & support services to ensure a zero downtime operations."}
                            serviceDescription={"Our Support & Maintenance Service ensures continuous assistance and upkeep for clients, such as" +
                                " resolving technical issues, updating software ot to fix your IT infrastructure problems and keeps your system running smoothly."}
                            icon={<MdSupportAgent className={"single-service-icon"} />}
                        />
                    </div>
                </div>

                <div className={"text-box-wrapper"}>
                    <h3>Why Choose AtRiZult
                        <span><BsFillQuestionCircleFill color={"#4198E8"} size={"1.5em"} /></span>
                    </h3>
                </div>

                <div>
                    <div className={"service-ad-competencies-img-txt-wrapper"}>
                        <div className={"service-ad-competencies-img-txt"}>
                            <img src={CoreCompetencies} alt={"competencies"}/>
                        </div>
                        <div className={"service-ad-competencies-img-txt"}>
                            <ul>
                                <li>Highly skilled and experienced IT consultants</li>
                                <li>Proven track record of successful projects</li>
                                <li>Tailored solutions to meet your specific business needs</li>
                                <li>Cutting-edge technology expertise</li>
                                <li>Commitment to delivering exceptional customer service</li>
                                <li>Cost-effective and scalable solutions</li>
                                <li>Strong focus on data security and privacy</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div className={"text-box-wrapper"}>
                <h3>Let us transform your business through the power of technology.</h3>
            </div>

            <div className={"our-service-teaser-wrapper"}>
                <Teaser href={"/our-services/consultation/servicePage=our-service-page"} text={"Discover how our consulting services can drive your organization's success"} buttonName={"Request Consultation"} />
            </div>

        </div>
    )
}