import React from "react";
import './AboutUs.scss'
import {Teaser} from "../../modules/teaser/Teaser";
import {Button} from "../../components/button/Button";
import aboutUsHeroImg from "../../resources/img/scrum.png"
import { FaUser } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { LiaProjectDiagramSolid } from "react-icons/lia";

export const AboutUs = () => {
    return (
        <div className={"about-us-container"}>
            <div className={"about-us-content-wrapper"}>
                <div className={"who-we-are-wrapper"}>
                    <h3>Who are we AtRiZult ?</h3>

                    <div className={"about-text-img-wrapper"}>
                        <div className={"width-70"}>
                            <img src={aboutUsHeroImg} alt={"about-us"} />
                        </div>
                        <div className={"about-us-background-img width-30"}>
                            <p>
                                AtRiZult is a dynamic and innovative consulting firm that offers a wide range of IT services across
                                various industries and sectors.
                                <br/><br/>
                                Established in 2023, our mission is to provide exceptional solutions
                                and outcomes for our clients, enabling them to thrive and succeed in an ever-changing world.
                                <br/><br/>
                                With a diverse team of experts and a commitment to excellence, we move to become the next big
                                thing in the consultancy industry.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={"about-atrizult-in-numbers"}>
                    <div className={"who-we-are-wrapper"}>
                        <h3>Atrizult in numbers</h3>
                    </div>
                    <div className={"atrizult-about-us-numbers-wrapper"}>
                        <a href={"/our-team"} className={"atrizult-about-us-numbers-box"}>
                            <p><FaUser className={"atrizult-numbers-icon"} /></p>
                            <p className={"resize-numbers"}>10</p>
                            <p>Employees</p>
                        </a>
                        <a href={"/projects"} className={"atrizult-about-us-numbers-box"}>
                            <p><LiaProjectDiagramSolid className={"atrizult-numbers-icon"} /></p>
                            <p className={"resize-numbers"}>2</p>
                            <p>Projects</p>
                        </a>
                        <a href={"/clients"} className={"atrizult-about-us-numbers-box"}>
                            <p><BsFillPeopleFill className={"atrizult-numbers-icon"} /></p>
                            <p className={"resize-numbers"}>1</p>
                            <p>Clients</p>
                        </a>
                        <a href={"#our-values"} className={"atrizult-about-us-numbers-box"}>
                            <p><BsFillPeopleFill className={"atrizult-numbers-icon"} /></p>
                            <p className={"resize-numbers"}>4</p>
                            <p>Our Values</p>
                        </a>
                    </div>
                </div>

                <div className={"ad-box-wrapper"}>
                    <h3>We are committed to encourage talents</h3>
                    <p>Join us in providing exceptional solutions & outcomes for our clients, enabling them to thrive
                        & succeed in an ever-changing world
                    </p>

                    <Button id={"explore-jobs-btn"} name={"explore-jobs-btn"} buttonName={"Explore Our Jobs"}
                            onClick={() => location.href="/careers"} disabled={false} />
                </div>

                <div className={"ad-box-wrapper"}>
                    <h3>See the team behind Atrizult success</h3>
                    <p>Visit our expert team page to see the faces of Atrizult</p>

                    <Button id={"explore-jobs-btn"} name={"explore-jobs-btn"} buttonName={"Explore Atrizult Team"}
                            onClick={() => location.href="/our-team"} disabled={false} />
                </div>

                <div className={"vision-mission-values-wrapper"}>
                    <div className={"vision-mission-values"}>
                        <h3><strong>Our Vision</strong></h3>
                        <p>
                            To be a leading IT consulting firm globally, known for our exceptional services,
                            cutting-edge solutions, and unwavering commitment to client satisfaction.
                        </p>
                    </div>

                    <div className={"vision-mission-values"}>
                        <h3><strong>Our Mission</strong></h3>
                        <p>
                            To assist businesses in achieving their goals by delivering customized IT solutions,
                            expert consulting services, and unparalleled support, thereby fostering long-term
                            relationships with our clients.
                        </p>
                    </div>

                    <div className={"vision-mission-values"} id={"our-values"}>
                        <h3><strong>Our Values</strong></h3>
                        <div className={"our-values-wrapper"}>
                            <p>T - Trust</p>
                            <p>H - Honesty</p>
                            <p>T - Transparency</p>
                            <p>I - Innovation</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"ad-box-wrapper"}>
                <h3>Let's Talk</h3>
                <p>
                    Join us on this exciting journey as we shape the future together. <br/>
                    Contact AtRiZult today and let us empower your organization to reach new heights of success!
                </p>

                <Button id={"about-us-to-contact-us-btn"} name={"about-us-to-contact-us-btn"} buttonName={"Get in Touch"}
                        onClick={() => location.href="/contact-us"} disabled={false} />
            </div>
        </div>
    )
}