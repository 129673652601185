import React, {ReactNode} from "react";
import './OurServiceAd.scss'

interface OurServiceAdProps {
    title: string,
    metaData: string
    icon: ReactNode
    serviceDescription: string
}

const redirectHandler = (title: string, serviceDescription: string) => {
    localStorage.setItem(title, serviceDescription)
    return location.href = `/our-services/consultation/${title}`
}

export const OurServiceAd = (props: OurServiceAdProps) => {
    return (
        <div className={"our-service-component-block"} onClick={() => redirectHandler(props.title, props.serviceDescription)}>
        {/*<div className={"our-service-component-block"} onClick={() => location.href = `/our-services/consultation/${props.title}`}>*/}
            <div className={"image-icon-wrapper"}>
                <h2><strong>{props.title}</strong></h2>
                {props.icon}
            </div>

            <div className={"single-service-metadata"}>
                <p>{props.metaData}</p>
            </div>
        </div>
    )
}
