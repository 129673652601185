import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomeApp from "./pages/home/HomeApp";
import {ContactForm} from "./pages/contactus/ContactForm";
import {UserRegister} from "./modules/userRegister/UserRegister";
import {ErrorPage} from "./pages/error/ErrorPage";
import {AboutUs} from "./pages/aboutus/AboutUs";
import {UserDataPolicy} from "./pages/user-data-policy/UserDataPolicy";
import {Career} from "./pages/career/Career";
import {CareerDetailPage} from "./pages/career/detailPage/CareerDetailPage";
import {JobApplicationPage} from "./pages/career/jobApplication/JobApplicationPage";
import {OurServices} from "./pages/ourservices/OurServices";
import {Consultation} from "./modules/consultation/Consultation";

/**
 * Houses all pages (page paths) that requires header and footer
 */

export const MainApplication = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomeApp />} />
                <Route path="contact-us" element={<ContactForm />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="our-services" element={<OurServices />} />
                <Route path="our-services/consultation/:servicePage" element={<Consultation />} />
                <Route path="consultation" element={<Consultation />} />
                <Route path="register" element={<UserRegister />} />
                <Route path="careers" element={<Career />} />
                <Route path="careers/careers-details-page/:jobIdQuery" element={<CareerDetailPage />} />
                <Route path="careers/careers-details-page/:jobIdQuery/apply" element={<JobApplicationPage />} />
                {/*<Route path="our-services/it-services" element={<UserDataPolicy />} />*/}
                <Route path="user-privacy" element={<UserDataPolicy />} />
                {/*<Route path="teaser" element={<Teaser buttonName={"Test Btn"} href={"/contact-us"} text={"testing testing testing"}/>} />*/}
                <Route path="*" element={<ErrorPage isError={false}/>} />
            </Routes>
        </BrowserRouter>
    );
}