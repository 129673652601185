import React, {useState} from "react";
import './TextArea.scss'
import classNames from "classnames";
import {FieldErrors} from "react-hook-form";
//import {faClose, faCheck} from "@fortawesome/free-solid-svg-icons";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ErrorNotification} from "../../pages/error/notification/ErrorNotification";
import {FaCheck} from "react-icons/fa";
import {IoMdClose} from "react-icons/io";

interface TextAreaProps {
    id: string,
    name: string,
    placeholder: string,
    value: string,
    errors: FieldErrors,
    onChange: any
    onBlur?: any
}

export const TextArea = (props: TextAreaProps) => {
    const [onLeaveTextArea, setOnLeaveTextArea] = useState(false)
    const error = props.errors && props.errors[props.name as string];

    const textAreaOnBlurHandler = () => {
        props.onBlur()
        !props.errors["message"] || !props.errors["message"].message && setOnLeaveTextArea(true)
    }


    return (
        <div>
            <div className={classNames("contact-label-textarea", {"message-success": (!error || !error.message) && onLeaveTextArea})} >
               <label>{props.placeholder + " *"}</label>
               <span className={"textarea-icons"}>
                    {
                        error && error.message && /*<FontAwesomeIcon color={"red"} icon={faClose}/>*/
                        <IoMdClose color={"red"} size={"1.5em"} />

                    }
                    {
                        (!error || !error.message) && props.value && <FaCheck color={"#262200"} size={"1.5em"} />
                        /*<FontAwesomeIcon color={"#262200"} icon={faCheck}/>*/
                    }
                </span>
                <textarea className={classNames("contact-textarea", {"textarea-error": error && error.message})}
                          id={props.id}
                          name={props.name}
                          placeholder={props.placeholder}
                          value={props.value}
                          required
                          minLength={20}
                          onChange={props.onChange}
                          onBlur={textAreaOnBlurHandler}

                />
            </div>
            <ErrorNotification name={props.name} fieldErrors={props.errors}  />
        </div>
    )
}