import React from "react";
import './CheckBox.scss'

interface CheckBoxProps extends React.HTMLProps<HTMLInputElement> {
    id: string,
    name: string,
    onClick?: any,
    checked: boolean
    label: string
    onChange: any
}

export const CheckBox = (props: CheckBoxProps) => {
    return (
        <div className={"checkbox-container"}>
            <input className={"format-checkbox"}
                   id={props.id}
                   type={"checkbox"}
                   name={props.name} required={true}
                   value={props.value}
                   onChange={props.onChange}/>

            <label className={"align-label-to-box"}><b>{props.label}
                <a href={"/user-data-policy"} style={{color: "#4198E8"}}> user data policy</a>
            </b></label>
        </div>
    )
}